import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { brand, brandHyphenated } from '../utils/brands'

export default function Home() {

  useEffect(() => {
    document.title = "Legal"
  })

  return (
    <div className="home-container">
      <img className="brand-logo" src={`/brands/${brandHyphenated}.svg`} alt={brand} />

      <ul className="lang-selector-container">

        <h2 className="margin-bottom-30">Legal</h2>
        
        <li>
          <Link to={`/terms`}>
            Terms of Service
          </Link>
        </li>

        <li>
          <Link to={`/privacy`}>
            Privacy Policy
          </Link>
        </li>
      </ul>

    </div>


  )
}
