export const translations = {
    "reset_password": "Réinitialiser le mot de passe",
    "new_password": "Nouveau mot de passe",
    "confirm_password": "Confirmez le mot de passe",
    "submit": "Soumettre",
    "error_password_min_length": "Le mot de passe doit comporter au moins 8 caractères",
    "error_password_max_length": "Le mot de passe ne peut pas dépasser 24 caractères",
    "error_passwords_do_not_match": "Les mots de passe ne correspondent pas",
    "password_success": "Le mot de passe a été changé avec succès",
    "success_new_password_mobile": "Vous pouvez maintenant vous connecter avec votre nouveau mot de passe sur votre application mobile.",
    "no_token_error": "Erreur. Veuillez suivre le lien dans votre e-mail de réinitialisation de mot de passe.",
    "reset_password_save_error": "Erreur. Veuillez réessayer. Vous n'avez qu'une heure après avoir demandé un mot de passe pour le réinitialiser.",
    "password_requirements": "Le mot de passe doit comporter entre 8 et 24 caractères, inclure un chiffre et contenir à la fois des lettres majuscules et minuscules. Aucun espace autorisé.",
    "updating": "Mise à jour"
};