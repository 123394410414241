export const translations = {
    "reset_password": "Zresetuj hasło",
    "new_password": "Nowe hasło",
    "confirm_password": "Potwierdź hasło",
    "submit": "Składać",
    "error_password_min_length": "Hasło musi mieć co najmniej 8 znaków",
    "error_password_max_length": "Hasło nie może być dłuższe niż 24 znaki",
    "error_passwords_do_not_match": "Hasła nie pasują do siebie",
    "password_success": "Hasło zostało pomyślnie zmienione",
    "success_new_password_mobile": "Teraz możesz zalogować się za pomocą nowego hasła w aplikacji mobilnej.",
    "no_token_error": "Błąd. Kliknij link w wiadomości e-mail dotyczącej resetowania hasła.",
    "reset_password_save_error": "Błąd. Proszę spróbuj ponownie. Po zażądaniu hasła masz tylko godzinę na jego zresetowanie.",
    "password_requirements": "Hasło musi mieć od 8 do 24 znaków, zawierać cyfrę, mieć duże i małe litery. Brak spacji.",
    "updating": "Aktualizacja"
};