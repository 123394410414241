export const translations = {
    "reset_password": "Wachtwoord opnieuw instellen",
    "new_password": "Nieuw paswoord",
    "confirm_password": "Bevestig wachtwoord",
    "submit": "Indienen",
    "error_password_min_length": "Wachtwoord moet minimaal 8 tekens lang zijn",
    "error_password_max_length": "Wachtwoord mag niet langer zijn dan 24 tekens",
    "error_passwords_do_not_match": "Wachtwoorden komen niet overeen",
    "password_success": "Wachtwoord succesvol veranderd",
    "success_new_password_mobile": "U kunt nu inloggen met uw nieuwe wachtwoord op uw mobiele app.",
    "no_token_error": "Fout. Volg de link in uw e-mail voor het opnieuw instellen van uw wachtwoord.",
    "reset_password_save_error": "Fout. Probeer het opnieuw. U heeft slechts één uur na het aanvragen van een wachtwoord om het opnieuw in te stellen.",
    "password_requirements": "Wachtwoord moet tussen 8 en 24 tekens lang zijn, een cijfer bevatten en zowel hoofdletters als kleine letters bevatten. Geen spaties toegestaan.",
    "updating": "Updaten"
};