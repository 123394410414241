export const translations = {
    "reset_password": "Obnovit heslo",
    "new_password": "Nové heslo",
    "confirm_password": "Potvrďte heslo",
    "submit": "Předložit",
    "error_password_min_length": "Heslo musí mít alespoň 8 znaků",
    "error_password_max_length": "Heslo nesmí být delší než 24 znaků",
    "error_passwords_do_not_match": "Hesla se neshodují",
    "password_success": "heslo bylo úspěšně změněno",
    "success_new_password_mobile": "Nyní se můžete přihlásit pomocí nového hesla ve své mobilní aplikaci.",
    "no_token_error": "Chyba. Klikněte na odkaz v e -mailu pro resetování hesla.",
    "reset_password_save_error": "Chyba. Prosím zkuste to znovu. Na obnovení hesla máte pouze jednu hodinu po zadání hesla.",
    "password_requirements": "Heslo musí mít 8 až 24 znaků, musí obsahovat číslici a musí mít velká i malá písmena. Mezery nejsou povoleny.",
    "updating": "Aktualizace"
};