export const translations = {
    "reset_password": "重设密码",
    "new_password": "新密码",
    "confirm_password": "确认密码",
    "submit": "提交",
    "error_password_min_length": "密码长度必须至少为 8 个字符",
    "error_password_max_length": "密码不能超过 24 个字符",
    "error_passwords_do_not_match": "密码不匹配",
    "password_success": "密码修改成功",
    "success_new_password_mobile": "现在，您可以在移动应用程序上使用新密码登录。",
    "no_token_error": "错误。请按照您的重置密码电子邮件中的链接进行操作。",
    "reset_password_save_error": "错误。请再试一次。请求密码后，您只有一小时的时间来重置密码。",
    "password_requirements": "密码必须介于 8 到 24 个字符之间，包括一个数字，同时包含大小写字母。不允许有空格。",
    "updating": "更新"
};