export const translations = {
    "reset_password": "Setel Ulang Kata Sandi",
    "new_password": "Kata sandi baru",
    "confirm_password": "Konfirmasi sandi",
    "submit": "Kirim",
    "error_password_min_length": "Kata sandi harus minimal 8 karakter",
    "error_password_max_length": "Kata sandi tidak boleh lebih dari 24 karakter",
    "error_passwords_do_not_match": "Sandi tidak cocok",
    "password_success": "Kata sandi berhasil diubah",
    "success_new_password_mobile": "Sekarang Anda dapat masuk dengan kata sandi baru di aplikasi seluler Anda.",
    "no_token_error": "Kesalahan. Silakan ikuti tautan di email setel ulang kata sandi Anda.",
    "reset_password_save_error": "Kesalahan. Silakan coba lagi. Anda hanya memiliki waktu satu jam setelah meminta kata sandi untuk meresetnya.",
    "password_requirements": "Kata sandi harus antara 8 dan 24 karakter, termasuk angka, memiliki huruf besar dan kecil. Tidak ada spasi yang diizinkan.",
    "updating": "Memperbarui"
};