import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyDe() {
  const termsUrl = buildTermsUrl('de');
  const privacyUrl = buildPrivacyUrl('de');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>
      DATENSCHUTZRICHTLINIE
      </h2>

      <p>
      Alliance Laundry Systems LLC („Alliance”, „wir”, „uns”, „unser”), stellt Ihnen eine mobile Anwendung und eine zugehörige Website (die „App” oder die „Website”) zur Verfügung (die Begriffe „Sie”, „Ihr” und „Ihre” beziehen sich auf alle Benutzer der App oder Website („Benutzer”)), damit Sie auf die Wäschereidienste unserer Drittanbieter (die „Wäscherei”) zugreifen können (diese Drittanbieterdienste werden als „Dienst” oder „Dienste” bezeichnet). Die betreffende Wäscherei ist der Verantwortliche für Ihre personenbezogenen Daten (gemäß der hierin enthaltenen Definition) und Alliance verarbeitet (gemäß der hierin enthaltenen Definition) Ihre personenbezogenen Daten im Namen der Wäscherei und als Auftragsverarbeiter der Wäscherei.
      </p>

      <p>
      Die Bestimmungen dieser Datenschutzrichtlinie („Datenschutzrichtlinie”) erläutern die Art und Weise, wie Ihre Daten verwendet werden. Wenn Sie Daten über die App oder die Website bereitstellen, die App herunterladen, sich für ein App-Konto (ein „Konto”) registrieren, irgendeinen Teil der App nutzen, Daten übermitteln oder Dienste nutzen, die von der Wäscherei über die App bereitgestellt werden, gelten die in dieser Datenschutzrichtlinie dargelegten Praktiken und Richtlinien. Diese Datenschutzrichtlinie soll die Datenschutzrichtlinie der Wäscherei ergänzen. Weitere Informationen darüber, wie die Wäscherei Ihre über die App oder die Website gesammelten personenbezogenen Daten verwendet, entnehmen Sie bitte der Datenschutzrichtlinie der Wäscherei.
      </p>

      <p>
      Begriffe, die hier nicht definiert sind, haben die Bedeutung, die in den Nutzungsbedingungen von Alliance unter<a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> angegeben ist; die Nutzungsbedingungen gelten als Bestandteil dieser Richtlinie.
      </p>

      <blockquote>
      VORBEHALTLICH DES GELTENDEN RECHTS KÖNNEN WIR DIESE DATENSCHUTZRICHTLINIE JEDERZEIT ÄNDERN. EINE SOLCHE ÄNDERUNG TRITT SOFORT IN KRAFT, SOBALD WIR DIE GEÄNDERTE DATENSCHUTZRICHTLINIE ENTWEDER AUF DER WEBSITE ODER IN DER APP VERÖFFENTLICHEN ODER SIE DARÜBER BENACHRICHTIGEN. BITTE ÜBERPRÜFEN SIE DIESE DATENSCHUTZRICHTLINIE REGELMÄSSIG, UM SICHERZUSTELLEN, DASS SIE ÜBER ALLE ÄNDERUNGEN INFORMIERT SIND. WENN SIE DIE ÄNDERUNGEN DER GEÄNDERTEN VEREINBARUNG NICHT AKZEPTIEREN, SOLLTEN SIE DIE NUTZUNG DER APP SOFORT EINSTELLEN. IHR FORTGESETZTER ZUGRIFF ODER IHRE FORTGESETZTE NUTZUNG DER APP GILT ALS IHRE KONKLUDENTE ZUSTIMMUNG ZU DEN GEÄNDERTEN DATENSCHUTZBESTIMMUNGEN.
      </blockquote>

      <p>
      Diese überarbeitete Datenschutzrichtlinie ist ab dem 1. April 2021 wirksam. Informationen darüber, wie wir mit personenbezogenen Daten für unsere eigenen Zwecke umgehen, finden Sie in unseren anderen Datenschutzrichtlinien (die unsere Praktiken in Bezug auf unser weiteres Geschäft regeln). Sie finden diese Datenschutzrichtlinien hier: <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
      IN DIESER DATENSCHUTZRICHTLINIE ABGEDECKTE DATEN.
      </h3>

      <p>
      Diese Datenschutzrichtlinie deckt die Verwendung Ihrer personenbezogenen Daten durch die Wäscherei ab, die Alliance im Namen und auf Anweisung der Wäscherei sammelt („verarbeitet”).
      </p>

      <h3>
      SAMMLUNG IHRER DATEN.
      </h3>

      <p>
      Um auf die App, die Website und die Dienste der Wäscherei zugreifen zu können, müssen Sie sich für ein Konto registrieren. Sie können sich für ein Konto registrieren, indem Sie bestimmte personenbezogene Daten, d. h. Daten, die zu Ihrer Identifizierung verwendet werden können („personenbezogene Daten”), bereitstellen, und zwar entweder durch:
      </p>

      <p>
      (1) die Eingabe solcher personenbezogenen Daten (z. B. und soweit zutreffend Ihr Titel, Ihr Name, Ihre E-Mail-Adresse, Adressen, Telefonnummern, Kreditkarten- und andere Finanzdaten, Ihr Alter, Ihr Geburtsdatum und bestimmte Präferenzen) direkt in der App oder auf der Website; oder
      </p>

      <p>
      (2) indem Sie uns den Zugriff auf Ihre personenbezogenen Daten aus Ihrem Profil im sozialen Netzwerk Facebook und/oder über Ihre Google-Zugangsdaten ermöglichen.
      </p>

      <p>
      Es liegt in Ihrer Verantwortung, uns aktuelle, vollständige, wahrheitsgemäße und genaue Daten, einschließlich personenbezogener Daten, anzugeben und diese Daten auf dem neuesten Stand zu halten. Wenn Sie diese Daten nicht bereitstellen, kann die Wäscherei Ihnen die Dienste möglicherweise nicht zur Verfügung stellen. Alliance übernimmt keine Verantwortung für Probleme oder Haftung im Zusammenhang mit ungenauen oder unvollständigen personenbezogenen Daten, sei es aufgrund einer unterlassenen Aktualisierung der personenbezogenen Daten durch Sie oder aus anderen Gründen.
      </p>

      <p>
      Zusätzlich zu den personenbezogenen Daten können wir die folgenden von Ihnen zur Verfügung gestellten Daten sammeln (zusammen mit Ihren personenbezogenen Datendie „Daten”):
      </p>

      <p>
        <ul>
          <li>
          Bestimmte Standard-Browser-Daten, z. B. Ihr Browsertyp, IP-Adresse und Zugriffszeiten;
          </li>

          <li>
          Daten im Zusammenhang mit Mitteilungen, die Sie uns senden (z. B. um ein Problem zu melden oder Anfragen zu stellen); und
          </li>

          <li>
          Transaktionsverlauf.
          </li>
        </ul>
      </p>

      <p>
      Wir werden Ihre personenbezogenen Daten nicht aufbewahren, es sei denn, dies ist durch ein anwendbares Gesetz vorgeschrieben oder erfolgt auf Anweisung der Wäscherei.
      </p>

      <h3>
      VERWENDUNG IHRER DATEN.
      </h3>

      <p>
      Die Wäscherei kann Ihre personenbezogenen Daten für eine Reihe von berechtigten Zwecken verwenden, u. a. damit die Wäscherei Ihnen die Dienste zur Verfügung stellen kann. Genauer gesagt: Wir stellen Ihre Daten der Wäscherei auf deren Weisung zur Verfügung, damit diese (i) Werbung und Inhalte anzeigen kann, die auf Ihre Präferenzen und Interessen zugeschnitten sind, (ii) die Dienste betreiben kann und (iii) auf Supportanfragen reagieren kann. Bestimmte Funktionen der
      </p>

      <p>
      App ermöglichen die soziale Vernetzung. Sie sollten bei der Nutzung dieser Funktionen sicherstellen, dass Sie keine personenbezogenen Daten übermitteln, von denen Sie nicht möchten, dass sie an beziehungsweise von anderen Benutzern gesendet, gesammelt oder verwendet werden, z. B. Profildaten oder Ihre E-Mail-Adresse.
      </p>

      <p>
      Die Wäscherei kann Ihre Daten auch verwenden, um mit Ihnen zu kommunizieren, z. B. um Ihnen vorgeschriebene und freiwillige Mitteilungen über die Dienste zukommen zu lassen. Von Zeit zu Zeit kann die Wäscherei Ihnen in Übereinstimmung mit der geltenden Gesetzgebung auch Umfragen oder Werbemails senden, um Sie über andere Produkte oder Dienste der Wäscherei zu informieren, es sei denn, Sie teilen uns zu irgendeinem Zeitpunkt mit, dass Sie zu diesem Zweck nicht kontaktiert werden möchten.
      </p>

      <h3>
      FÜR BENUTZER AUSSERHALB DER VEREINIGTEN STAATEN VON AMERIKA
      </h3>

      <p>
      Alliance hat seinen Hauptsitz in den Vereinigten Staaten von Amerika und kann, vorbehaltlich der geltenden Gesetze, Ihre Daten in die Vereinigten Staaten von Amerika übertragen, um Ihre Daten der Wäscherei zur Verfügung zu stellen. Für den Fall, dass Alliance Ihre Daten in ein anderes Land als Ihr Heimatland überträgt, stellt Alliance sicher, dass die Übertragung in Übereinstimmung mit dem geltenden Recht erfolgt.
      </p>

      <p>
      Wenn wir Daten von Benutzern im Vereinigten Königreich oder im EWR außerhalb des Vereinigten Königreichs oder des EWR oder, im Falle von Benutzern in Australien, außerhalb Australiens übertragen, werden wir oder sie den Empfängern dieser Daten vertragliche Verpflichtungen auferlegen, Ihre Daten (a) im Falle von Benutzern im Vereinigten Königreich oder im EWR nach dem im Vereinigten Königreich oder im EWR geforderten Standard oder (b) im Falle von Benutzern in Australien dem geforderten Standard in Australien zu schützen.
      </p>

      <p>
      In Übereinstimmung mit den Anweisungen der Wäscherei können wir auch von jedem Empfänger verlangen, sich an internationale Rahmenbedingungen gebunden zu halten, die einen sicheren Datenaustausch ermöglichen sollen. Bei Übertragung durch uns können wir Ihre Daten auch in folgenden Fällen übertragen: (a) Im Falle von Benutzern im Vereinigten Königreich oder im EWR: (i) wenn die Übermittlung in ein Land erfolgt, dessen Datenschutzniveau von der Regierung des Vereinigten Königreichs oder der Europäischen Kommission als angemessen erachtet wird; oder (ii) wenn Alliance eine der in der geltenden Gesetzgebung genannten „geeignete Garantien” geleistet hat; oder (b) im Falle von Benutzern in Australien: (i) wenn wir angemessene Schritte unternehmen, um sicherzustellen, dass der Empfänger dieser Daten nicht gegen die australischen Grundsätze des Datenschutzes (Australien Privacy Principles, „APP”) gemäß dem Datenschutzgesetzt „Privacy Act 1988 (Cth)” verstößt; oder (ii) wenn wir für die Handlungen oder Praktiken des Empfängers dieser Daten verantwortlich bleiben.
      </p>

      <h3>
      WEITERGABE IHRER PERSONENBEZOGENEN DATEN AN DRITTE.
      </h3>

      <p>
      Sofern in dieser Datenschutzrichtlinie nicht anderweitig dargelegt, werden Ihre personenbezogenen Daten nur mit der Wäscherei, Alliance und den von ihr kontrollierten Tochtergesellschaften und verbundenen Unternehmen geteilt. Ihre personenbezogenen Daten werden ohne Ihr Wissen nicht an andere Dritte weitergegeben.
      </p>

      <p>
      Wenn wir Ihre Daten an Dritte weitergeben, erfolgt eine solche Weitergabe zu jeder Zeit in Übereinstimmung mit den geltenden Datenschutzgesetzen und den von der Wäscherei erteilten Anweisungen. Wenn Sie auf Ihr Konto zugreifen, können Sie sich dafür entscheiden, Ihre personenbezogenen Daten an die Wäscherei weiterzugeben.
      </p>

      <p>
      Die Wäscherei kann Alliance beauftragen, andere Unternehmen mit der Erbringung begrenzter Verwaltungs- und Kommunikationsdienstleistungen für die Wäscherei zu beauftragen, z. B. das Hosting der Website, die Verarbeitung und Zustellung von Sendungen, die Bereitstellung von Kundensupport oder die Erbringung von Kreditkartenverarbeitungsdienstleistungen. Alliance hat das Recht, Ihre personenbezogenen Daten gemäß den Anweisungen der Wäscherei an diese Drittunternehmen weiterzugeben, um die Funktion und den Betrieb der Website und der App zu verwalten und aufrechtzuerhalten, damit die Wäscherei Ihnen die Dienste bereitstellen kann. Diese Unternehmen dürfen nur solche personenbezogenen Daten erhalten, die für die Bereitstellung der jeweiligen Dienste erforderlich sind, und die Wäscherei ermächtigt diese Unternehmen nicht zu einer Verwendung Ihrer personenbezogenen Daten, die über den alleinigen Zweck der Erbringung der von Alliance angeforderten Dienste im Namen der Wäscherei hinausgeht.
      </p>

      <p>
      Zu den anderen Empfängern oder Kategorien von Empfängern, an die die Wäscherei Ihre personenbezogenen Daten weitergeben kann, gehören: (i) Empfänger in Verbindung mit gesetzlichen Verpflichtungen oder dem Schutz Rechtsansprüche (z. B. Verbrechensprävention); (ii) Betrugsbekämpfungsbehörden, die sie zur Überprüfung Ihrer Identität verwenden können; (iii) andere Unternehmen, mit denen die Wäscherei fusionieren oder von denen sie übernommen werden möchte, oder mit denen die Wäscherei eine Umstrukturierung durchläuft; oder (iv) alle Rechtsnachfolger im Falle eines Liquidations- oder Verwaltungsverfahrens der Wäscherei.
      </p>

      <p>
      Wir sind nicht verpflichtet, die Gültigkeit von Durchsuchungsbefehlen, Vorladungen oder ähnlichen behördlichen Aufforderungen, die wir erhalten, in Frage zu stellen oder anzufechten. In einigen Fällen kann sich eine Wäscherei entscheiden, Vermögenswerte zu kaufen oder zu verkaufen. Bei dieser Art von Transaktionen gehören Benutzerdaten typischerweise zum übertragenen Betriebsvermögen. Im Falle der Übernahme einer Wäscherei oder im Wesentlichen aller ihrer Vermögenswerte gehören zudem die Benutzerdaten zu den Vermögenswerten, die übertragen oder von einer dritten Partei erworben werden.
      </p>

      <h3>
      RECHTSGRUNDLAGE FÜR DIE VERARBEITUNG.
      </h3>

      <p>
      Diese Datenschutzrichtlinie beschreibt die Zwecke, für die wir Ihre Daten im Namen der Wäscherei sammeln und für die die Wäscherei Ihre personenbezogenen Daten verwenden kann. Die Wäscherei kann sich für die Verarbeitung Ihrer Daten auf eine der folgenden Rechtsgrundlagen stützen: (a) Sie haben der Wäscherei eine eindeutige Zustimmung zur Verarbeitung Ihrer Daten für einen bestimmten Zweck erteilt; (b) die Verarbeitung ist erforderlich, damit die Wäscherei ihre Verpflichtungen aus einem Vertrag, den Sie mit der Wäscherei geschlossen haben, erfüllen kann; (c) die Verarbeitung ist erforderlich, damit die Wäscherei die gesetzlichen Bestimmungen einhalten kann (dies schließt keine vertraglichen Verpflichtungen ein); (d): die Verarbeitung ist erforderlich, um das Leben einer Person zu schützen; (e) die Verarbeitung ist für die Wäscherei erforderlich, um eine Aufgabe im öffentlichen Interesse oder für offizielle Funktionen der Wäscherei zu erfüllen, und die Aufgabe oder Funktion hat eine eindeutige gesetzliche Grundlage; oder (f) die Verarbeitung ist für die berechtigten Interessen der Wäscherei oder die berechtigten Interessen eines Dritten erforderlich (es sei denn, es gibt einen guten Grund, Ihre Daten zu schützen, der diese berechtigten Interessen überwiegt). Weitere Einzelheiten darüber, auf welche Rechtsgrundlage sich die Wäscherei bei der Verarbeitung Ihrer Daten stützt, finden Sie in der Datenschutzrichtlinie der Wäscherei.
      </p>

      <h3>
      SICHERHEIT IHRER PERSONENBEZOGENEN DATEN.
      </h3>

      <p>
      Alliance verwendet branchenübliche Standardverfahren wie Firewalls, Verschlüsselung und (in bestimmten Bereichen) Sicherheitsprotokolle (Secure Socket Layers) zum Schutz Ihrer Daten in Übereinstimmung mit den Anweisungen der Wäscherei. Dennoch ist kein Sicherheitssystem undurchdringlich, so effektiv die Verschlüsselungstechnologie auch ist. Obwohl wir physische, elektronische und administrative Sicherheitsvorkehrungen treffen, um Ihre personenbezogenen Daten vor unbefugtem oder unangemessenem Zugriff zu schützen, kann Alliance weder die Sicherheit unserer Datenbank garantieren noch eine Garantie dafür geben, dass die von Ihnen bereitgestellten Daten bei der Übertragung über das Internet nicht abgefangen werden. Alliance empfiehlt, dass Sie Ihr Kennwort an niemanden weitergeben. Wenn wir personenbezogene Daten über Sie im Namen der Wäscherei durch die Nutzung unserer App und/oder der Wäscherei-Dienste sammeln, wird die Wäscherei diese Daten nur so lange aufbewahren, wie es unter Anwendung vernünftiger Maßstäbe notwendig ist, um die angeforderte Aktivität zu erfüllen, oder wie es gesetzlich vorgeschrieben ist.
      </p>

      <h3>
      AUTOMATISCH GESAMMELTE DATEN.
      </h3>

      <p>
      Wir können (im Namen der Wäscherei) bestimmte Arten von Daten erhalten und speichern, wenn Sie mit der App und/oder der Dienste interagieren. Die Wäscherei kann uns anweisen, automatisch Daten von Ihrem Browser zu erhalten und diese in unseren Serverprotokollen aufzuzeichnen, einschließlich Ihrer IP-Adresse, Cookie-Informationen und der von Ihnen aufgerufenen Seite.
      </p>

      <p>
      Darüber hinaus kann die Wäscherei Drittanbieter für Analysen und Produkte nutzen, um Daten darüber zu erhalten, zusammenzustellen und zu analysieren, wie Benutzer die Dienste nutzen und mit ihnen interagieren. Auf Anweisung der Wäscherei können wir diesen Analyseanbietern Daten zur Verfügung stellen, um Statistiken und andere Angaben darüber zu erhalten, wie die Benutzer die Wäscherei nutzen und mit ihr interagieren. Diese Analyseanbieter können eine Vielzahl etablierter oder neuer Tracking-Technologien oder -Tools (insbesondere Cookies, Web-Beacons, HTTP-Cache, lokal freigegebene Objekte und sogenannte Persistent Identifiers zur eindeutigen Benennung einer digitalen Ressource) verwenden, um Ihren Computer oder Ihr Gerät zu erkennen und/oder diese Informationen zu sammeln oder zusammenzustellen. Alliance hat keine Kontrolle über die Technologien, Tools oder Praktiken von Drittanbietern, die der Wäscherei Analyseprodukte und -dienste zur Verfügung stellen. Weitere Informationen darüber, welche Daten die Wäscherei automatisch sammelt, finden Sie in der Datenschutzrichtlinie der Wäscherei.
      </p>

      <h3>
      SAMMLUNG UND VERWENDUNG VON PERSONENBEZOGENEN DATEN VON KINDERN.
      </h3>

      <p>
      Die App und die Dienste sind für ein allgemeines Publikum gedacht, und Alliance sammelt wissentlich keine personenbezogenen Daten von Kindern. Während des Registrierungsprozesses für ein Konto erfasst Alliance Daten über das Alter. Wenn Benutzer angeben, jünger als 13 Jahre zu sein, wird Alliance diese Benutzer daran hindern, personenbezogene Daten anzugeben.
      </p>

      <h3>
      IHRE RECHTE IN BEZUG AUF IHRE PERSONENBEZOGENEN DATEN.
      </h3>

      <p>
      Abhängig von Ihrem Rechtssystem haben Sie bestimmte Rechte in Bezug auf Ihre personenbezogenen Daten, darunter das Recht: (i) über die Verarbeitung Ihrer personenbezogenen Daten informiert zu werden (d. h. (ii) Zugang zu oder eine Kopie von allen personenbezogenen Daten zu verlangen, die die Wäscherei über Sie gespeichert hat); (iii) die Berichtigung Ihrer personenbezogenen Daten zu verlangen, wenn Sie der Meinung sind, dass diese unrichtig sind; (iv) die Löschung Ihrer personenbezogenen Daten zu verlangen; (v) der Verarbeitung Ihrer personenbezogenen Daten für einen bestimmten Zweck zu widersprechen oder zu verlangen, dass die Wäscherei die Verwendung Ihrer Daten einstellt; (vi) zu verlangen, dass Sie nicht einer Entscheidung unterworfen werden, die aufgrund einer automatisierten Verarbeitung ergeht, und dass Schutzmaßnahmen getroffen werden, wenn auf der Grundlage Ihrer personenbezogenen Daten ein Profil erstellt wird; (vii) uns aufzufordern, eine Kopie Ihrer personenbezogenen Daten an Sie oder an einen anderen Dienstleister oder einen Dritten zu übermitteln, sofern dies technisch möglich ist; und (viii) die Wäscherei jederzeit per E-Mail an die Wäscherei aufzufordern, die Zusendung von Werbemitteilungen an Sie einzustellen oder zu beginnen. Abhängig von Ihrem Rechtssystem können einige oder alle dieser Rechte in irgendeiner Weise eingeschränkt sein. Beachten Sie, dass zudem jede elektronische Mitteilung, die Ihnen die Wäscherei sendet, Einzelheiten darüber enthält, wie Sie sich abmelden können. Bitte beachten Sie, dass es bis zu 28 Tage dauern kann, bis Ihre Anfrage bearbeitet wird.
      </p>

      <p>
      Wenn Sie eines Ihrer Rechte in Bezug auf Ihre personenbezogenen Daten ausüben möchten, setzen Sie sich direkt mit der Wäscherei in Verbindung. Alternativ können Sie uns unter softwaresupport@alliancels.com kontaktieren und wir werden die Wäscherei benachrichtigen. Kennzeichnen Sie dazu derartige E-Mails mit dem Betreff „Rechte der betroffenen Person”. Die Wäscherei wird eine solche Anfrage in Übereinstimmung mit der Datenschutzgesetzgebung bearbeiten.
      </p>

      <h3>
      FRAGEN ODER KOMMENTARE ZU DIESER DATENSCHUTZRICHTLINIE.
      </h3>

      <p>
      Wenn Sie Fragen oder Anmerkungen zur Verwendung Ihrer personenbezogenen Daten oder zu dieser Datenschutzrichtlinie haben, wenden Sie sich bitte an die Wäscherei. Alternativ wenden Sie sich bitte an softwaresupport@alliancels.com und wir leiten Ihre Anfrage an die jeweilige Wäscherei weiter.
      </p>

      <p>
      Wenn Sie in der EU ansässig sind und Bedenken bezüglich der Verwendung Ihrer Daten durch die Wäscherei haben, sind Sie außerdem berechtigt, eine Beschwerde bei der Datenschutzbehörde Ihres Landes einzureichen. Kontaktinformationen finden Sie unter: https://edpb.europa.eu/about-edpb/board/members_en.
      </p>

      <p>
      Wenn Sie im Vereinigten Königreich ansässig sind, haben Sie bei Bedenken hinsichtlich der Verwendung Ihrer Daten das Recht, eine Beschwerde beim Büro des Informationsbeauftragen, dem Information Commissioner's Office, einzureichen. Kontaktinformationen finden Sie unter: https://ico.org.uk/global/contact-us/.
      </p>

      <p>
      Wenn Sie in Australien ansässig sind, erkennen Sie an, dass wir und die Wäscherei an die APP gebunden sind. Wenn Sie Fragen, Bedenken oder Beschwerden zu dieser Datenschutzrichtlinie oder zum Umgang mit Ihren personenbezogenen Daten haben, wenden Sie sich bitte an die oben genannte Adresse. Wir werden dann Ihre Beschwerde prüfen und innerhalb einer angemessenen Frist beantworten. Wenn Sie mit der Bearbeitung Ihrer Beschwerde oder Ihres Anliegens unzufrieden sind, können Sie sich an das Büro des australischen Informationsbeauftragten, das Office of the Australian Information Commissioner, wenden:
      </p>

      <p>
      Office of the Australian Information
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      Telefon: 1300 363 992
      <br />
      Mail: enquiries@oaic.gov.au
      <br />
      If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
      </p>

    </div>
  )
}
