export const translations = {
    "reset_password": "Сброс пароля",
    "new_password": "новый пароль",
    "confirm_password": "Подтвердите пароль",
    "submit": "Представлять на рассмотрение",
    "error_password_min_length": "Пароль должен быть не менее 8 символов",
    "error_password_max_length": "Пароль не может быть длиннее 24 символов",
    "error_passwords_do_not_match": "Пароли не соответствуют",
    "password_success": "Пароль успешно изменен",
    "success_new_password_mobile": "Теперь вы можете войти в мобильное приложение с новым паролем.",
    "no_token_error": "Ошибка. Пожалуйста, перейдите по ссылке в электронном письме для сброса пароля.",
    "reset_password_save_error": "Ошибка. Пожалуйста, попробуйте еще раз. У вас есть только один час после запроса пароля, чтобы сбросить его.",
    "password_requirements": "Пароль должен содержать от 8 до 24 символов, включать цифры, буквы верхнего и нижнего регистра. Пробелы не допускаются.",
    "updating": "Обновление"
};