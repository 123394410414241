import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { useEffect } from 'react'

import TermsContainer from './components/terms/TermsContainer'
import PrivacyContainer from './components/privacy/PrivacyContainer';
import Home from './components/Home';
import ResetPassword from './components/reset-password/ResetPassword';
import { Toaster } from 'react-hot-toast'

function App() {
  const brand = process.env.REACT_APP_BRAND.toLowerCase();

  console.log('v1.5.8');

  useEffect(() => {
    document.title = 'Home'
  })

  useEffect(() => {
    document.body.classList.add(`body-${brand}`);
  })

  return (
    <Router>
      <Toaster />
      <div className={`body brand-${brand}`}>

        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>

          <Route path="/reset-password">
            <ResetPassword />
          </Route>

          <Route path='/terms'>
            <TermsContainer />
          </Route>

          <Route path='/privacy'>
            <PrivacyContainer />
          </Route>

          {/* catch all */}
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
