import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyJp() {
  const termsUrl = buildTermsUrl('jp');
  const privacyUrl = buildPrivacyUrl('jp');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>プライバシーポリシー</h2>

      <p>
        Alliance Laundry Systems LLC（「Aｌliance」、「当社」、「我々」、「私たち」）は、お客様（「お客様」という用語は、アプリまたはサイトのすべてのユーザー（「ユーザー」）を指すものとします）が当社を通じて第三者のランドリープロバイダー（「ランドリー施設」）のランドリーサービス（これらの第三者のサービスは「サービス」と呼びます）を利用するために、モバイルアプリケーションと関連ウェブサイト（「アプリ」または「サイト」）を提供します。 関連するランドリー施設はお客様の個人情報（本書で定義）の管理者であり、Allianceは（本書で定義するとおり）、ランドリー施設に代わって、およびランドリー施設の処理者としてお客様の個人情報を処理します。
      </p>

      <p>
        本個人情報保護方針（「個人情報保護方針」）の条件は、お客様のデータの使用方法を説明するものです。 アプリまたはサイトを通じて情報を提供する場合、ダウンロード、アプリのアカウント（「アカウント」）の登録、アプリのいずれかの部分の使用、情報の送信、またはアプリを介してランドリー施設が提供するサービスの使用を行う場合、本個人情報保護方針で概説されている慣行およびポリシーが適用されるものとします。 本個人情報保護方針は、ランドリー施設の個人情報保護方針を補足することを目的としています。アプリまたはサイトを通じて収集されたお客様の個人情報の使用方法の詳細については、ランドリー施設の方針を参照してください。
      </p>

      <p>
        ここで定義されていない用語は、<a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> に掲載されているAllianceの利用規約に記載されているとおりの意味とし、本方針に組み込まれるものとします。
      </p>

      <blockquote>
        適用法に従い、当社は、本個人情報保護方針をいつでも変更することができ、そのような変更は、変更された個人情報保護方針をサイト、アプリに掲載した、またはお客様に通知した直後に有効になります。 お客様は、本個人情報保護方針を定期的に確認して、変更を認識しているようにしてください。 お客様は、変更された契約に加えられた変更を承諾できない場合、アプリのすべての使用を直ちに停止する必要があります。 お客様が、アプリに継続的にアクセスする、または使用を継続する場合、変更された個人情報保護方針を最終的に承諾されたものと見なされます。
      </blockquote>

      <p>
        本個人情報保護方針の改訂版は、2021年4月1日から有効です。 当社が、独自の目的で個人データを処理する方法に関する情報については、当社の他の個人情報保護方針（より広範なビジネスに関連する当社の慣行を管理する）を参照してください。 <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
        本個人情報保護方針の対象となる情報。
      </h3>

      <p>
        本個人情報保護方針は、Allianceが、ランドリー施設に代わって、またランドリー施設の指示の下で収集したお客様の個人情報を、ランドリー施設が使用することを対象としています（「処理」）。
      </p>

      <h3>
        お客様情報の収集。
      </h3>

      <p>
        お客様は、アプリ、サイト、ランドリー施設のサービスにアクセスするためにアカウントに登録する必要があります。 特定の個人データを提供することにより、アカウントに登録できます。このような個人データは、次のいずれかの方法によって、お客様を特定するために使用できる情報（「個人情報」）を指します。
      </p>

      <p>
        (1)	アプリやサイトに個人情報を直接入力する（該当する場合、敬称、名前、電子メールアドレス、住所、電話番号、クレジットカードおよびその他の財務情報、年齢、生年月日、特定の好み、など）。または、
      </p>

      <p>
        (2)	FacebookソーシャルネットワーキングサイトのプロフィールやGoogleログイン情報からお客様の個人情報にアクセスすることを当社に許可する。
      </p>

      <p>
        個人情報を含む、最新の、完全で、真実で正確な情報を提供し、そのような情報を最新の状態に保つことは、お客様の責任です。 情報が提供されない場合、ランドリー施設はサービスを提供できなくなる可能性があります。 Allianceは、個人情報の更新の失敗またはその他の原因によるものかどうかにかかわらず、不正確または不完全な個人情報に関連する問題または責任について一切負いません。
      </p>

      <p>
        当社は、個人情報に加えて、お客様が提供する次の情報を収集することがあります（お客様の個人情報とあわせて「情報」と呼びます）。
      </p>

      <p>
        <ul>
          <li>
          ブラウザの種類、IPアドレス、アクセス時間など、特定の標準的なブラウザ情報。
          </li>

          <li>
          当社がお客様に送るコミュニケーションに関連する情報（例えば、問題を報告したり、質問を送信したりするため）。および、
          </li>

          <li>
          取引履歴。
          </li>
        </ul>
      </p>

      <p>
        当社は、適用法またはランドリー施設の指示がない限り、お客様の個人情報を保持することはありません。
      </p>

      <h3>
        お客様情報の使用。
      </h3>

      <p>
        ランドリー施設は、ランドリー施設がお客様にサービスを提供できるようにするなど、さまざまな正当な目的でお客様の個人情報を使用する場合があります。 より具体的には次のとおりです。 当社は、ランドリー施設の指示によりお客様の情報をランドリー施設に提供し、（i）お客様の好みや興味に合わせた広告やコンテンツを表示し、（ii）サービスを運用し、（iii）サポートリクエストに対応できるようにします。 特定の機能
      </p>

      <p>
        アプリはソーシャルネットワーキングが可能です。 お客様は、これらの機能を使用する際に、プロファイルの詳細や電子メールアドレスなど、他のユーザーに送信、収集、使用されたくない個人情報を送信しないようにする必要があります。
      </p>

      <p>
        また、ランドリー施設は、サービスに関する必須の通知または裁量の通知を提供するなど、お客様の情報を使用してお客様と通信する場合があります。 ランドリー施設は、適用される法律に従って、ランドリー施設から利用できる他の製品またはサービスを通知するために、アンケートまたは販促用メールを送信する場合があります。お客様がこの目的のために連絡を受けることを希望しない場合は、いつでもご連絡ください。
      </p>

      <h4>
        アメリカ合衆国以外のユーザー
      </h4>

      <p>
        Allianceはアメリカ合衆国に本社を置き、適用法に従い、ランドリー施設にお客様の情報を提供するために、お客様の情報をアメリカ合衆国に転送する場合があります。 Allianceがお客様のデータを自国以外に転送する場合、Allianceは、適用法に準拠して転送が行われることを保証します。
      </p>

      <p>
        英国またはEEA内のユーザーの情報を英国またはEEA外に転送する場合、またはオーストラリアのユーザーの場合はオーストラリア国外に転送する場合、当社またはランドリー施設は、お客様の情報を保護するために、（a）英国またはEEAのユーザーの場合、英国またはEEAで要求される標準、または（b）オーストラリアのユーザーの場合、オーストラリアで要求される標準で、そのデータの受信者に契約上の義務を課します。
      </p>

      <p>
        また当社は、ランドリー施設からの指示に従って、安全なデータ共有を可能にすることを目的とした国際的フレームワークへの加入を受信者に要求する場合があります。 当社が転送する場合、以下の場合にもお客様の情報を転送することがあります。 （a）英国またはEEAのユーザーの場合： （i）英国政府または欧州委員会によってお客様の情報を適切に保護すると見なされる国への転送の場合。または（ii）Allianceが、該当する法律で言及されている「適切な保護手段」の1つを導入した場合。または、（b）オーストラリアのユーザーの場合： （i）1988年プライバシー法（連邦法）に基づき、その情報の受信者がオーストラリアのプライバシー原則（「APP」）に違反しないように、合理的な措置を講じた場合。または（ii）当社がその情報の受信者の行為または慣行について引き続き責任を負う場合。
      </p>

      <h3>
        お客様の個人情報の第三者への開示。
      </h3>

      <p>
        本個人情報保護方針に記載されている場合を除き、お客様の個人情報は、ランドリー施設、Alliance、およびその管理下にある子会社および関連会社とのみ共有され、お客様の個人情報は、お客様の知らないうちに他の第三者に開示されることはありません。
      </p>

      <p>
        当社がお客様の情報を第三者と共有する場合、当社は、常に適用されるデータ保護法およびランドリー施設による指示に準拠しつつ開示を行います。 お客様は、アカウントにアクセスすることにより、個人情報をランドリー施設と共有することを選択できます。
      </p>

      <p>
        ランドリー施設は、サイトのホスティング、メールの処理と配信、カスタマーサポートの提供、クレジットカード処理サービスの提供など、ランドリー施設に限定的な管理および通信サービスを提供するために他の会社を雇うことをAllianceに要求する場合があります。 Allianceは、ランドリー施設がお客様にサービスを提供できるように、サイトおよびアプリの特徴、機能、および操作を管理および維持する目的で、ランドリー施設の指示に従ってこれらのサードパーティ企業にお客様の個人情報を開示する権利を有します。 これらの企業は、それぞれのサービスを提供するために必要な個人情報のみを取得することが許可され、ランドリー施設は、Allianceがランドリー施設の代理で要求するサービスを提供することのみを目的とする場合を除き、これらの企業がお客様の個人情報を使用することを許可しません。
      </p>

      <p>
        ランドリー施設がお客様の個人情報を共有する可能性のあるその他の受信者または受信者のカテゴリには、次のものがあります。 （i）法的義務または法的権利の保護（防犯など）に関連して。（ii）お客様の身元を確認するためにそれを使用する可能性のある詐欺防止機関。（iii）ランドリー施設が合併または買収を計画している他の事業体、またはランドリー施設がその事業体と再編成されている場合。または（iv）ランドリー施設が清算または管理される場合、株式譲受人。
      </p>

      <p>
        当社は、捜索令状、召喚状、またはその他同様の政府からの要請を受けた場合、その有効性について質問したり、異議を唱えたりする必要はありません。 場合によっては、ランドリー施設が資産の購入または売却を選択することがあります。 このような取引では通常、ビジネス資産の一部としてユーザー情報が譲渡されます。 さらに、ランドリー施設が、あるいは実質的にその資産のすべてが買収された場合、ユーザー情報は第三者に譲渡される、または第三者が取得する資産の一部になります。
      </p>

      <h3>
        処理の法的根拠。
      </h3>

      <p>
        本個人情報保護方針は、当社がランドリー施設に代わってお客様の情報を収集する目的、およびランドリー施設がお客様の個人情報を使用する目的について説明するものです。 ランドリー施設は、お客様の情報を処理するために、以下の法的根拠のいずれかに依存する場合があります。（a）お客様は、ランドリー施設が特定の目的のためにお客様の情報を処理することに対し明確な同意を示した場合。（b）ランドリー施設がお客様との契約に基づく義務を履行するために処理が必要な場合。（c）ランドリー施設が法律を遵守するために処理が必要な場合（契約上の義務を含まない）。（d）： 人命を守るために処理が必要な場合。（e）ランドリー施設が公益のために業務を実行する上で処理が必要な場合、または、ランドリー施設の公式機能のために必要な場合（業務または機能に法律上明確な根拠がある）。または（f）ランドリー施設の正当な利益のために、または第三者の正当な利益のために処理が必要な場合（これらの正当な利益を無効にし、お客様情報を保護する正当な理由がない場合に限る）。 ランドリー施設が依存するデータ処理に関する法的根拠の詳細については、ランドリー施設の個人情報保護方針を参照してください。
      </p>

      <h3>
        お客様の個人情報の安全性。
      </h3>

      <p>
        Allianceは、ファイアウォール、暗号化、および（特定の領域では）セキュアソケットレイヤーなどの標準的な業界慣行を使用して、ランドリー施設の指示に従い情報を保護します。 ただし、暗号化技術は効果的ですが、侵入できないセキュリティシステムはありません。 Allianceは、お客様の個人情報を不正または不適切なアクセスから保護するために、物理的、電子的、および管理上の保護手段を維持していますが、データベースのセキュリティを保証できません。また、お客様が提供する情報が、インターネットを介してAllianceに送信されている間に傍受されないことを保証することもできません。 Allianceは、パスワードを誰にも開示しないことをお勧めします。 当社が、当社のアプリおよび/またはランドリー施設サービスを使用して、ランドリー施設に代わってお客様の個人情報を収集する場合、ランドリー施設は、要求された活動を遂行するために合理的に必要な期間に限り、または法律で義務付けられている期間に限り、お客様の個人情報を保持します。
      </p>

      <h3>
        自動的に収集される情報。
      </h3>

      <p>
        当社は、お客様がアプリやサービスを操作するたびに、特定の種類の情報を（ランドリー施設に代わって）受け取り、保存する場合があります。 ランドリー施設は、IPアドレス、Cookie情報、要求されたページなど、ブラウザからサーバーログの情報を自動的に受信して記録するように当社に指示する場合があります。
      </p>

      <p>
        さらに、ランドリー施設は、サードパーティの分析プロバイダーおよび製品を使用して、ユーザーがサービスをどのように使用および操作しているかに関する情報を取得、コンパイル、および分析する場合があります。 当社は、ランドリー施設から指示された場合、ユーザーがランドリー施設をどのように使用および操作しているかに関する統計情報およびその他の情報を取得する目的で、これらの分析プロバイダーに情報を提供する場合があります。 これらの分析プロバイダーは、さまざまな確立された、または新しい追跡テクノロジーまたはツール（Cookie、Webビーコン、HTTPキャッシュ、ローカル共有オブジェクト、永続的識別子を含むがこれらに限定されない）を使用して、コンピューターまたはデバイスを認識したり、情報を収集またはコンパイルしたりする場合があります。 Allianceは、ランドリー施設に分析製品およびサービスを提供するサードパーティのテクノロジー、ツール、または慣行を管理することはできません。 ランドリー施設が自動的に収集する情報の詳細については、ランドリー施設の個人情報保護方針を参照してください。
      </p>

      <h3>
        お子様の個人情報の収集と使用。
      </h3>

      <p>
        アプリとサービスは一般の視聴者を対象としており、Allianceは、お子様から故意に個人情報を収集することはありません。 Allianceは、アカウントの登録プロセス中に年齢情報を収集し、ユーザーが13歳未満であると識別した場合、そのようなユーザーによる個人情報の提供をブロックします。
      </p>

      <h3>
        お客様の個人情報に関連する権利。
      </h3>

      <p>
        管轄によっては、お客様は、以下の権利を含む個人データに関する特定の権利を有する場合があります。 （i）お客様の個人データ（すなわち、本個人情報保護方針およびランドリー施設の個人情報保護方針で提供される情報）の処理について通知される権利。（ii）ランドリー施設が保持するお客様の個人データにアクセスするまたはコピーを要求する権利。（iii）不正確であると思われる場合は、個人データの修正を要求する権利。（iv）お客様の個人データの消去を要求する権利。（v）お客様の個人データが特定の目的のために処理されることに異議を唱える、またはランドリー施設がお客様の情報の使用を停止するよう要求する権利。（vi）自動処理に基づく決定の対象とならないように要求し、個人データに基づいてプロファイリングされている場合はセーフガードを設定するように要求する権利。（vii）技術的に可能な場合は、お客様の個人データのコピーをお客様、または別のサービスプロバイダーまたはサードパーティに転送するよう当社に依頼する権利。（viii）ランドリー施設に電子メールを送信することにより、いつでもマーケティングメッセージの送信を停止または開始するようにランドリー施設に依頼する権利。 管轄によっては、これらの権利の一部またはすべてが何らかの方法で制限される場合があります。 さらに、ランドリー施設から送信される各電子メールには、購読を解除する方法の詳細が含まれていることに注意してください。 要求の処理には最大28日かかる場合がありますのでご注意ください。
      </p>

      <p>
        お客様が個人情報に関する権利を行使したい場合は、ランドリー施設にご連絡ください。 当社にご連絡することをご希望の場合は、softwaresupport@alliancels.comにご連絡ください。当社からランドリー施設に通知いたします。 電子メールをご送信いただく際には件名に「データ主体の権利」と記入してください。 ランドリー施設は、データ保護法に従ってそのような要求を処理します。
      </p>

      <h3>
        本個人情報保護方針に関する質問またはコメント。
      </h3>

      <p>
        個人情報または本個人情報保護方針の使用について質問またはコメントがある場合は、ランドリー施設に連絡してください。 または、softwaresupport@alliancels.comにお問い合わせいただければ、当社から質問を関連するランドリー施設に通知いたします。
      </p>

      <p>
        EUにお住まいの場合、ランドリー施設によるお客様情報の使用について懸念がある場合は、お客様には、お住まいの国のデータ保護規制当局に苦情を申し立てる権利もあります。 連絡先の詳細については、以下を参照してください。 https://edpb.europa.eu/about-edpb/board/members_en。
      </p>

      <p>
        英国にお住まいの場合、客様情報の使用について懸念がある場合は、お客様には情報コミッショナーオフィスに苦情を申し立てる権利があります。 連絡先の詳細については、以下を参照してください。 https://ico.org.uk/global/contact-us/。
      </p>

      <p>
        お客様はオーストラリアにお住まいの場合は、当社とランドリー施設がAPPに拘束されていることを認めるものとします。 本個人情報保護方針、または個人情報の取り扱いについて質問、懸念、苦情がある場合は、上記のアドレスに連絡してください。苦情を確認し、合理的な期間内に対応いたします。 苦情または懸念の処理に不満がある場合は、オーストラリア情報コミッショナーのオフィスに連絡することができます。
      </p>

      <p>
      Office of the Australian Information Commissioner
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      電話: 1300 363 992
      <br />
      メール: enquiries@oaic.gov.au
      <br />
      If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
      </p>
    </div>
  )
}
