import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { brandHyphenated, brand } from '../../utils/brands'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import toast from 'react-hot-toast'

export default function ResetPassword() {
  const [hasTouched, setHasTouched] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('')
  const [isPasswordSuccess, setIsPasswordSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { t, i18n } = useTranslation();

  let query = useQuery(); // { token, mobile, onboarding, isDev }
  const queryLocale = query.get('locale')?.toLowerCase();

  useEffect(() => {
    i18n.changeLanguage(queryLocale ? queryLocale : 'en')
  }, [queryLocale])

  useEffect(() => {
    document.title = t('reset_password')
  }, [t])

  useEffect(() => {
    validatePasswords(password, confirmPassword, hasTouched)
  }, [password, confirmPassword, hasTouched])

  const handleSubmit = (e) => {
    e.preventDefault()
    changePassword()
  }

  const handlePasswordUpdate = (e, type) => {
    setHasTouched(true)
    if (type === 'password') { setPassword(e.target.value) }
    if (type === 'confirmPassword') { setConfirmPassword(e.target.value) }
  }

  const validatePasswords = (password, confirmPassword, hasTouched) => {
    if (!hasTouched) { return }

    setErrorPassword('')
    setErrorConfirmPassword('')

    // min of 8 - 24 length, must have capital letter and number
    if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/)) {
      setErrorPassword(t('password_requirements'))
    }

    // no blank spaces
    if (!password.match(/^\S*$/)) {
      setErrorPassword(t('password_requirements'))
    }


    if (password.length && password !== confirmPassword) { setErrorConfirmPassword(t('error_passwords_do_not_match')) }
  }

  const changePassword = async function() {
    const token = query.get('token')

    if (!token) { toast.error(t('no_token_error')) }

    setIsLoading(true);

    axios.post(`${process.env.REACT_APP_API_URL}/auth/setPassword`, {
      password,
      token
    }, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'app': process.env.REACT_APP_APP_HEADER
      },
    }).then(() => {
      toast.success(t('password_success'))
      setIsLoading(false)
      setIsPasswordSuccess(true)
    }).catch(() => {
      toast.error(t('reset_password_save_error'))
      setIsLoading(false)
    })
  }

  if (isPasswordSuccess) {
    return <PasswordSuccess />
  }

  return (
    <div >
      <img className="brand-logo" src={`/brands/${brandHyphenated}.svg`} alt={brand} />

      <ul className="lang-selector-container">
        <h2 className="capitlize">{t('reset_password')}</h2>
        {/* <li>query token: {query.get('token')}</li>
        <li>query mobile: {query.get('mobile')}</li>
        <li>query onboarding: {query.get('onboarding')}</li>
        <li>password: {password}</li>
        <li>confirm: {confirmPassword}</li> */}

        {!query.get('token') && <>
          <div className="m-4 mb-0 p-4 bg-red-600 text-white">
            {t('no_token_error')}
          </div>
        </>}

        <div className="w-full p-4 mt-4">
          <form className="" onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-4">
              <PasswordInputValidate
                label={t('new_password')}
                id="password"
                value={password}
                disabled={isLoading}
                handleIputUpdate={(e) => {
                  handlePasswordUpdate(e, 'password')
                }}
                errorMessage={errorPassword}
              />
            </div>

            <div className="mb-4">
              <PasswordInputValidate
                label={t('confirm_password')}
                id="confirmPassword"
                value={confirmPassword}
                disabled={isLoading}
                handleIputUpdate={(e) => {
                  handlePasswordUpdate(e, 'confirmPassword')
                }}
                errorMessage={errorConfirmPassword}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                onClick={(e) => handleSubmit(e)}
                disabled={isLoading || errorConfirmPassword || errorPassword || !hasTouched}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
                type="button">
                {isLoading ? t('updating') : t('submit')}
              </button>
            </div>
          </form>
        </div>
      </ul>
    </div>

  )
}

function PasswordInputValidate({label, id, value, handleIputUpdate, errorMessage, disabled}) {
  return <>
    <label className="block text-gray-700 text-sm font-normal mb-2" htmlFor={id}>
      {label}
    </label>
    <input
      className={`${errorMessage ? 'border-red-500' : ''} appearance-none border border-gray-300 focus:border-blue-400	rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
      id={id}
      type="password"
      autocorrect="false"
      autocapitalize="none"
      value={value}
      disabled={disabled}
      onChange={(e) => handleIputUpdate(e) }
      placeholder={label} />
    {errorMessage && <>
      <p className="text-red-500 text-xs italic">{errorMessage}</p>
    </>}
  </>
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordSuccess() {
  const { t } = useTranslation();

  return (
    <div >
    <img className="brand-logo" src={`/brands/${brandHyphenated}.svg`} alt={brand} />

    <ul className="reset-password-success text-center">
      <img src="/success.png" alt="Success" />
      <h2 className="text-xl font-semibold">{t('password_success')}</h2>

      <div className="w-full p-4 pb-2">
        <h2 className="text-lg">{t('success_new_password_mobile')}</h2>
      </div>
    </ul>
  </div>
  )
}
