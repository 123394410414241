export const translations = {
    "reset_password": "Resetta la password",
    "new_password": "Nuova password",
    "confirm_password": "Conferma password",
    "submit": "Invia",
    "error_password_min_length": "La password deve contenere almeno 8 caratteri",
    "error_password_max_length": "La password non può essere più lunga di 24 caratteri",
    "error_passwords_do_not_match": "le passwords non corrispondono",
    "password_success": "password cambiata con successo",
    "success_new_password_mobile": "Ora puoi accedere con la tua nuova password sulla tua app mobile.",
    "no_token_error": "Errore. Segui il link nell'e-mail di reimpostazione della password.",
    "reset_password_save_error": "Errore. Per favore riprova. Hai solo un'ora dopo aver richiesto una password per reimpostarla.",
    "password_requirements": "La password deve essere compresa tra 8 e 24 caratteri, includere un numero, avere lettere maiuscole e minuscole. Non sono ammessi spazi.",
    "updating": "In aggiornamento"
};