export const translations = {
    "reset_password": "Reset Password",
    "new_password": "New Password",
    "confirm_password": "Confirm Password",
    "submit": "Submit",
    "error_password_min_length": "Password must be at least 8 characters long",
    "error_password_max_length": "Password cannot be longer than 24 characters",
    "error_passwords_do_not_match": "Passwords do not match",
    "password_success": "Password changed successfully",
    "success_new_password_mobile": "Now you can log in with your new password on your mobile app.",
    "no_token_error": "Error. Please follow the link in your reset password email.",
    "reset_password_save_error": "Error. Please try again. You only have one hour after requesting a password to reset it.",
    "password_requirements": "Password must be between 8 and 24 characters, include a number, have both upper and lower case letters. No spaces allowed.",
    "updating": "Updating"
};