export const translations = {
    "reset_password": "Jelszó visszaállítása",
    "new_password": "új jelszó",
    "confirm_password": "Jelszó megerősítése",
    "submit": "Beküldés",
    "error_password_min_length": "A jelszónak legalább 8 karakter hosszúnak kell lennie",
    "error_password_max_length": "A jelszó nem lehet hosszabb 24 karakternél",
    "error_passwords_do_not_match": "A jelszavak nem egyeznek",
    "password_success": "A jelszó sikeresen megváltozott",
    "success_new_password_mobile": "Most bejelentkezhet új jelszavával mobilalkalmazásában.",
    "no_token_error": "Hiba. Kérjük, kövesse a jelszó -visszaállítási e -mailben található linket.",
    "reset_password_save_error": "Hiba. Kérlek próbáld újra. A jelszó kérése után mindössze egy órája van a jelszó visszaállítására.",
    "password_requirements": "A jelszónak 8 és 24 karakter között kell lennie, tartalmaznia kell egy számot, valamint kis- és nagybetűket is. Nincs szóköz.",
    "updating": "Frissítés"
};