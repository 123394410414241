export const translations = {
    "reset_password": "重設密碼",
    "new_password": "新密碼",
    "confirm_password": "確認密碼",
    "submit": "提交",
    "error_password_min_length": "密碼長度必須至少為 8 個字符",
    "error_password_max_length": "密碼不能超過 24 個字符",
    "error_passwords_do_not_match": "密碼不匹配",
    "password_success": "密碼修改成功",
    "success_new_password_mobile": "現在，您可以在移動應用程序上使用新密碼登錄。",
    "no_token_error": "錯誤。請按照您的重置密碼電子郵件中的鏈接進行操作。",
    "reset_password_save_error": "錯誤。請再試一次。請求密碼後，您只有一小時的時間來重置密碼。",
    "password_requirements": "密碼必須介於 8 到 24 個字符之間，包括一個數字，同時包含大小寫字母。不允許有空格。",
    "updating": "更新"
};