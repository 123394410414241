import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyCs() {
  const termsUrl = buildTermsUrl('cs');
  const privacyUrl = buildPrivacyUrl('cs');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>
      Zásady ochrany osobních údajů
      </h2>

      <p>
      Alliance Laundry Systems LLC (dále jen „Alliance“, „my“, „nás“, „naše“) vám (pojmy „vy“, „vám“, „vás“ a „váš“ se vztahují na všechny uživatele aplikace nebo stránek, dále jen „uživatelé“) poskytuje mobilní aplikaci a související webové stránky (dále jen „aplikace“ nebo „stránky“), které umožňují přístup k prádelnám našich třetích stran (dále jen „prádelna“) (tyto služby třetích stran jsou označovány jako „služba“ nebo „služby“). Příslušná prádelna je správcem vašich osobních údajů (jak jsou definovány v tomto dokumentu) a společnost Alliance vaše osobní údaje zpracovává (jak je definováno v tomto dokumentu) jménem prádelny a jako zpracovatel údajů prádelny.
      </p>

      <p>
      Podmínky těchto zásad ochrany osobních údajů (dále jen „zásady ochrany osobních údajů“) vysvětlují způsob, jakým jsou vaše údaje používány. Pokud poskytujete informace prostřednictvím aplikace nebo stránek, stahujete, registrujete si účet aplikace (dále jen „účet“), používáte jakoukoli část aplikace, poskytujete informace nebo používáte služby poskytované prádelnou prostřednictvím aplikace, použijí se postupy a zásady uvedené v těchto zásadách ochrany osobních údajů. Tyto zásady ochrany osobních údajů jsou určeny k doplnění zásad ochrany osobních údajů prádelny. Další informace o tom, jak prádelna používá vaše osobní údaje shromážděné prostřednictvím aplikace nebo stránek, naleznete v zásadách dané prádelny.
      </p>

      <p>
      Pojmy, které zde nejsou definovány, mají význam stejný jako v podmínkách služeb společnosti Alliance, které jsou uvedeny na adrese <a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> a jsou zde začleněny.
      </p>

      <blockquote>
      NA ZÁKLADĚ PLATNÝCH PRÁVNÍCH PŘEDPISŮ MŮŽEME TYTO ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ KDYKOLI ZMĚNIT A TYTO ZMĚNY NABÝVAJÍ PLATNOSTI OKAMŽITĚ PO ZVEŘEJNĚNÍ UPRAVENÝCH ZÁSAD OCHRANY OSOBNÍCH ÚDAJŮ NA STRÁNKÁCH, V APLIKACI NEBO PO OZNÁMENÍ. PRAVIDELNĚ TYTO ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ KONTROLUJTE, ABYSTE MĚLI POVĚDOMÍ O VEŠKERÝCH ZMĚNÁCH. POKUD ZMĚNY PROVEDENÉ V UPRAVENÉ SMLOUVĚ NEPŘIJMETE, MĚLI BYSTE VEŠKERÉ POUŽÍVÁNÍ APLIKACE NEPRODLENĚ UKONČIT. POKRAČUJÍCÍ PŘÍSTUP K APLIKACI NEBO JEJÍ POUŽÍVÁNÍ BUDE POVAŽOVÁNO ZA VÁŠ JEDNOZNAČNÝ SOUHLAS S UPRAVENÝMI ZÁSADAMI OCHRANY OSOBNÍCH ÚDAJŮ.
      </blockquote>

      <p>
      Tyto revidované zásady ochrany osobních údajů jsou platné od 1. dubna 2021. Informace o tom, jak nakládáme s osobními údaji pro naše vlastní účely, se dočtete v našich dalších zásadách ochrany osobních údajů (upravujících naše postupy ve vztahu k našemu širšímu podnikání), které naleznete zde: <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
      INFORMACE, KTERÉ JSOU PŘEDMĚTEM TĚCHTO ZÁSAD OCHRANY OSOBNÍCH ÚDAJŮ.
      </h3>

      <p>
      Tyto zásady ochrany osobních údajů se vztahují na používání vašich osobních údajů prádelnou, které společnost Alliance shromáždí jménem prádelny a podle jejích pokynů (dále jen „procesy“).
      </p>

      <h3>
      SHROMAŽĎOVÁNÍ VAŠICH ÚDAJŮ.
      </h3>

      <p>
      Pro přístup k aplikaci, stránkám a službám prádelny bude nutné zaregistrovat účet. Účet si můžete zaregistrovat poskytnutím určitých osobních údajů, což znamená informace, které lze použít k vaší identifikaci (dále jen „osobní údaje“), a to buď prostřednictvím:
      </p>

      <p>
      (1) zadání těchto osobních údajů (například titulu, jména, e-mailové adresy, fyzických adres, telefonního čísla, údajů z kreditní karty a dalších finančních údajů, věku, data narození a určitých preferencí) přímo do aplikace nebo na stránky; nebo
      </p>

      <p>
      (2) tím, že nám umožníte přístup k vašim osobním údajům z vašeho profilu na sociálních sítích Facebook a/nebo z vašich přihlašovacích údajů Google.
      </p>

      <p>
      Nesete odpovědnost za poskytnutí aktuálních, úplných, pravdivých a přesných informací, včetně osobních údajů, a za jejich průběžnou aktualizaci. Pokud tyto informace neposkytnete, může to znamenat, že vám prádelna nebude schopna poskytovat služby. Společnost Alliance nenese odpovědnost za žádné problémy nebo závazky související s nepřesnými nebo neúplnými osobními údaji, ať už z důvodu neohlášení změny těchto osobních údajů z vaší strany nebo jiných.
      </p>

      <p>
      Kromě osobních údajů můžeme shromažďovat následující informace, které poskytnete (společně s vašimi osobními údaji dále jen „informace“):
      </p>

      <p>
        <ul>
          <li>
          Některé standardní informace o prohlížeči, jako je typ prohlížeče, IP adresa a doba přístupu;
          </li>

          <li>
          Informace v souvislosti s komunikací, kterou nám posíláte (například za účelem nahlášení problému nebo odeslání dotazů); a
          </li>

          <li>
          Historii transakcí.
          </li>
        </ul>
      </p>

      <p>
      Vaše osobní údaje budeme uchovávat v souladu s platnými zákony a podle pokynů prádelny.
      </p>

      <h3>
      POUŽÍVÁNÍ VAŠICH INFORMACÍ.
      </h3>

      <p>
      Prádelna může vaše osobní údaje použít k řadě legitimních účelů, včetně toho, aby vám mohla poskytovat služby. Konkrétně: vaše informace poskytujeme prádelně podle jejích pokynů, abychom jí umožnili (i) zobrazovat reklamy a obsah přizpůsobený vašim preferencím a zájmům (ii) provozovat služby a (iii) reagovat na žádosti o podporu. Některé funkce
      </p>

      <p>
      aplikace umožňují používání sociálních sítí. Při používání těchto funkcí byste se měli ujistit, že neodesíláte žádné osobní údaje, které nechcete, aby byly odeslány, shromažďovány nebo používány jinými uživateli, jako jsou podrobnosti profilu nebo e-mailová adresa.
      </p>

      <p>
      Prádelna může také použít vaše informace ke komunikaci s vámi, například k poskytování povinných nebo nezávazných oznámení o službách. V souladu s platnými právními předpisy vám může prádelna čas od času také zasílat průzkumy nebo propagační e-maily s cílem informovat vás o dalších produktech nebo službách dostupných v prádelně. Kdykoli nám však můžete dát vědět, že si nepřejete být za tímto účelem kontaktováni.
      </p>

      <h3>
      PRO UŽIVATELE MIMO SPOJENÉ STÁTY AMERICKÉ
      </h3>

      <p>
      Společnost Alliance má sídlo ve Spojených státech amerických a v souladu s platnými právními předpisy může přenášet vaše informace do Spojených států amerických za účelem poskytnutí vašich informací prádelně. V případě, že společnost Alliance přenese údaje mimo vaši domovskou zemi, zajistí, aby byl přenos proveden v souladu s platnými zákony.
      </p>

      <p>
      Pokud přenášíme informace o uživatelích ve Spojeném království nebo EHP mimo Spojené království nebo EHP, nebo v případě uživatelů v Austrálii mimo Austrálii, uložíme příjemcům těchto údajů smluvní povinnost chránit vaše informace, (a) v případě uživatelů ve Spojeném království nebo EHP, podle norem požadovaných ve Spojeném království nebo EHP nebo, (b) v případě uživatelů v Austrálii, podle norem v Austrálii.
      </p>

      <p>
      V souladu s pokyny prádelny můžeme také požadovat, aby se každý příjemce zavázal dodržovat mezinárodní rámce, které mají umožnit bezpečné sdílení údajů. V případě přenosů, které provádíme my, můžeme také přenášet vaše informace, pokud: (a) v případě uživatelů ve Spojeném království nebo EHP: (i) se převod uskuteční do země, kterou vláda Spojeného království nebo Evropská komise považuje za zemi poskytující odpovídající ochranu vašich informací, nebo (ii) společnost Alliance zavede jedno z „vhodných ochranných opatření“ uvedených v platných právních předpisech, nebo (b) v případě uživatelů v Austrálii: (i) podnikneme přiměřené kroky, které zajistí, že příjemce těchto informací neporuší australské zásady ochrany osobních údajů („APP“) podle zákona o ochraně osobních údajů z roku 1988 (Cth); nebo (ii) poneseme nadále odpovědnost za jednání nebo postupy příjemce těchto informací.
      </p>

      <h3>
      POSKYTNUTÍ VAŠICH OSOBNÍCH ÚDAJŮ TŘETÍM STRANÁM.
      </h3>

      <p>
      S výjimkou případů popsaných v těchto zásadách ochrany osobních údajů budou vaše osobní údaje sdíleny pouze s prádelnou, společností Alliance a jí řízenými dceřinými a přidruženými společnostmi a vaše osobní údaje nebudou bez vašeho vědomí poskytnuty žádné jiné třetí straně.
      </p>

      <p>
      Pokud sdílíme vaše informace s třetími stranami, každé takové poskytnutí se vždy provádí v souladu s platnými zákony o ochraně údajů a jakýmikoli pokyny vydanými prádelnou. Přístupem ke svému účtu se můžete rozhodnout sdílet své osobní údaje s prádelnou.
      </p>

      <p>
      Prádelna může požádat společnost Alliance, aby najala další společnosti, které budou prádelně poskytovat omezené administrativní a komunikační služby, jako je hosting stránek, zpracování a doručování poštovních zásilek, poskytování zákaznické podpory nebo poskytování služeb zpracování kreditních karet. Společnost Alliance má právo sdělit vaše osobní údaje v souladu s pokyny prádelny těmto třetím stranám za účelem správy a údržby funkcí a provozu stránek a aplikace, aby vám prádelna mohla poskytovat služby. Těmto společnostem bude povoleno získat pouze takové osobní údaje, které jsou nezbytné pro poskytování příslušných služeb, a prádelna nepovoluje těmto společnostem používat vaše osobní údaje, s výjimkou výhradního účelu poskytování služeb požadovaných společností Alliance jménem prádelny.
      </p>

      <p>
      Mezi další příjemce nebo kategorie příjemců, se kterými může prádelna sdílet vaše osobní údaje, patří: (i) sdílení v souvislosti se zákonnými povinnostmi nebo ochranou zákonných práv (jako je předcházení trestné činnosti); (ii) agentury pro prevenci podvodům, které je mohou použít k ověření vaší totožnosti; (iii) jiné obchodní subjekty, které prádelna plánuje získat nebo se s nimi sloučit, nebo pokud prádelna projde reorganizací s tímto subjektem; nebo (iv) jakýkoli právní nástupce v případě úpadku nebo likvidace prádelny.
      </p>

      <p>
      Nejsme povinni zpochybňovat platnost jakéhokoli příkazu k prohlídce, předvolání nebo jiného podobného vládního požadavku, který obdržíme. V některých případech se prádelna může rozhodnout pro nákup nebo prodej aktiv. U těchto typů transakcí jsou informace o uživatelích typicky jedním z převáděných obchodních aktiv. Kromě toho, pokud by došlo k akvizici prádelny nebo významné části jejích aktiv, informace o uživatelích budou jedním z aktiv převedených nebo získaných třetí stranou.
      </p>

      <h3>
      PRÁVNÍ ZÁKLAD PRO ZPRACOVÁNÍ.
      </h3>

      <p>
      Tyto zásady ochrany osobních údajů popisují účely, za kterými shromažďujeme vaše údaje jménem prádelny a za kterými může prádelna vaše osobní údaje používat. Prádelna se může při zpracování vašich údajů spoléhat na jeden z následujících právních základů; (a) poskytli jste jasný souhlas prádelně se zpracováním vašich informací pro konkrétní účel; (b) zpracování je nezbytné pro to, aby prádelna splnila své povinnosti vyplývající ze smlouvy, kterou jste uzavřeli s prádelnou; (c) zpracování je nezbytné pro to, aby prádelna dodržela zákony (nezahrnuje smluvní závazky); (d): zpracování je nezbytné k ochraně něčího života; (e) zpracování je nezbytné pro to, aby prádelna mohla plnit úkoly ve veřejném zájmu nebo v rámci oficiálních funkcí prádelny, a úkol nebo funkce má jasný právní základ; nebo (f) zpracování je nezbytné pro oprávněné zájmy prádelny nebo oprávněné zájmy třetí strany (pokud neexistuje dobrý důvod k ochraně vašich informací, který tyto oprávněné zájmy převažuje). Podrobnější informace o tom, o jaký právní základ se prádelna opírá při zpracování vašich údajů, naleznete v zásadách ochrany osobních údajů prádelny.
      </p>

      <h3>
      ZABEZPEČENÍ VAŠICH OSOBNÍCH ÚDAJŮ.
      </h3>

      <p>
      Společnost Alliance používá standardní postupy platné v celém odvětví, jako jsou firewally, šifrování a (v některých oblastech) protokol secure sockets layer za účelem ochrany vašich informací v souladu s pokyny prádelny. I když je šifrovací technologie účinná, žádný bezpečnostní systém není neproniknutelný. Ačkoli uplatňujeme fyzická, elektronická a administrativní bezpečnostní opatření na ochranu vašich osobních údajů před neoprávněným nebo nepřiměřeným přístupem, nemůžeme zaručit bezpečnost naší databáze ani nemůžeme zaručit, že informace, které poskytnete, nebudou zachyceny při přenosu do společnosti Alliance přes internet. Společnost Alliance doporučuje nikomu nesdělovat své heslo. Pokud shromažďujeme vaše osobní údaje jménem prádelny prostřednictvím naší aplikace a/nebo služeb prádelny, prádelna bude uchovávat tyto informace pouze tak dlouho, jak je přiměřeně nezbytné pro splnění požadované činnosti nebo jak to mohou vyžadovat zákony.
      </p>

      <h3>
      INFORMACE SHROMAŽĎOVANÉ AUTOMATICKY.
      </h3>

      <p>
      Můžeme přijímat a ukládat (jménem prádelny) určité typy informací, kdykoli budete s aplikací a/nebo službou komunikovat. Prádelna nás může pověřit, abychom v protokolech našeho serveru automaticky přijímali a zaznamenávali informace z vašeho prohlížeče, včetně vaší IP adresy, informací o souborech cookie nebo o stránce, kterou jste požadovali.
      </p>

      <p>
      Kromě toho může prádelna využívat poskytovatele a produkty analýzy třetích stran k získávání, sestavování a analýze informací o tom, jak uživatelé používají služby a komunikují s nimi. Na pokyn prádelny můžeme poskytovat informace těmto poskytovatelům analýzy za účelem získání statistik a dalších informací o tom, jak uživatelé používají prádelnu a jak s ní komunikují. Tito poskytovatelé analýzy mohou k rozpoznání vašeho počítače nebo zařízení a/nebo ke shromažďování či sestavování těchto informací používat řadu zavedených nebo nových sledovacích technologií či nástrojů (mimo jiné soubory cookie, webové signály, mezipaměť HTTP, místní sdílené objekty a trvalé identifikátory). Společnost Alliance nemá žádnou kontrolu nad technologiemi, nástroji nebo postupy třetích stran, které prádelně poskytují analytické produkty a služby. Další informace o tom, které informace prádelna automaticky shromažďuje, naleznete v zásadách ochrany osobních údajů prádelny.
      </p>

      <h3>
      SHROMAŽĎOVÁNÍ A POUŽÍVÁNÍ OSOBNÍCH ÚDAJŮ DĚTÍ.
      </h3>

      <p>
      Aplikace a služby jsou určeny pro široké publikum a společnost Alliance vědomě neshromažďuje žádné osobní údaje od dětí. Během procesu registrace účtu společnost Alliance shromažďuje informace o věku, a pokud se uživatelé identifikují jako mladší 13 let, společnost těmto uživatelům zabrání v poskytování osobních údajů.
      </p>

      <h3>
      VAŠE PRÁVA VE VZTAHU K VAŠIM OSOBNÍM ÚDAJŮM.
      </h3>

      <p>
      V závislosti na vaší jurisdikci můžete mít určitá práva, pokud jde o vaše osobní údaje, včetně práva: (i) být informováni o zpracování vašich osobních údajů (tj. informací uvedených v těchto zásadách ochrany osobních údajů a zásadách ochrany osobních údajů prádelny); (ii) požádat o přístup k osobním údajům, které o vás prádelna uchovává, nebo o jejich kopii; (iii) požádat o opravu vašich osobních údajů, pokud se domníváte, že jsou nepřesné; (iv) požádat o vymazání vašich osobních údajů; (v) vznést námitku proti zpracování vašich osobních údajů pro určitý účel nebo požádat, aby prádelna přestala používat vaše údaje; (vi) požádat prádelnu, aby se na vás nevztahovalo rozhodnutí založené na automatizovaném zpracování a aby byla zavedena bezpečnostní opatření, pokud jste profilováni na základě vašich osobních údajů; (vii) požádat nás, abychom předali kopii vašich osobních údajů vám nebo jinému poskytovateli služeb nebo třetí straně, pokud je to technicky proveditelné; a (viii) požádat prádelnu, aby vám kdykoli přestala nebo začala zasílat marketingové zprávy, zasláním e-mailu prádelně. V závislosti na vaší jurisdikci mohou být některá nebo všechna tato práva nějakým způsobem omezena. Upozorňujeme, že každá elektronická pošta, kterou vám prádelna zašle, bude obsahovat podrobnosti o tom, jak se můžete odhlásit z jejího odběru. Upozorňujeme, že vyřízení žádosti může trvat až 28 dní.
      </p>

      <p>
      Pokud si přejete uplatnit některá ze svých práv ve vztahu k vašim osobním údajům, obraťte se na prádelnu. Pokud chcete, můžete nás kontaktovat na adrese softwaresupport@alliancels.com a my prádelnu informujeme. Tyto e-maily by měly být označeny předmětem „Práva subjektů údajů“. Prádelna takovou žádost zpracuje v souladu s právními předpisy o ochraně údajů.
      </p>

      <h3>
      OTÁZKY NEBO PŘIPOMÍNKY TÝKAJÍCÍ SE TĚCHTO ZÁSAD OCHRANY OSOBNÍCH ÚDAJŮ.
      </h3>

      <p>
      Máte-li jakékoli dotazy nebo připomínky týkající se používání vašich osobních údajů nebo těchto zásad ochrany osobních údajů, obraťte se na prádelnu. Případně pište na adresu softwaresupport@alliancels.com a my váš dotaz předáme příslušné prádelně.
      </p>

      <p>
      Pokud se nacházíte v EU a máte jakékoli obavy ohledně používání vašich údajů prádelnou, máte také právo podat stížnost regulačnímu orgánu pro ochranu údajů ve vaší zemi. Kontaktní údaje: https://edpb.europa.eu/about-edpb/board/members_en.
      </p>

      <p>
      Pokud se nacházíte ve Spojeném království a máte jakékoli obavy ohledně použití vašich údajů, máte právo podat stížnost u úřadu komisaře pro informace. Kontaktní údaje: https://ico.org.uk/global/contact-us/.
      </p>

      <p>
      Pokud se nacházíte v Austrálii, berete na vědomí, že my a prádelna jsme vázáni zásadami APP. Máte-li jakékoli dotazy, obavy nebo stížnosti týkající se těchto zásad ochrany osobních údajů nebo toho, jak nakládáme s vašimi osobními údaji, napište na výše uvedenou adresu a my vaši stížnost přezkoumáme a v přiměřené lhůtě odpovíme. Pokud nejste spokojeni se zpracováním stížnosti nebo obavy, můžete se obrátit na úřad australského komisaře pro informace:
      </p>

      <p>
      Office of the Australian Information Commissioner
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      Telefon: 1300 363 992
      <br />
      mail: enquiries@oaic.gov.au
      <br />
      Pokud se nacházíte v Kalifornii, USA, podívejte se také na náš dodatek k zásadám ochrany osobních údajů CCPA, který se nachází zde: <a href={cppaUrl} target="_blank" rel="noreferrer">click here.</a>
      </p>

    </div>
  )
}
