export const translations = {
    "reset_password": "Passwort zurücksetzen",
    "new_password": "Neues Kennwort",
    "confirm_password": "Kennwort bestätigen",
    "submit": "Einreichen",
    "error_password_min_length": "Das Passwort muss mindestens 8 Zeichen lang sein",
    "error_password_max_length": "Das Passwort darf nicht länger als 24 Zeichen sein",
    "error_passwords_do_not_match": "Passwörter stimmen nicht überein",
    "password_success": "Das Passwort wurde erfolgreich geändert",
    "success_new_password_mobile": "Jetzt können Sie sich mit Ihrem neuen Passwort in Ihrer mobilen App anmelden.",
    "no_token_error": "Fehler. Bitte folgen Sie dem Link in Ihrer E-Mail zum Zurücksetzen des Passworts.",
    "reset_password_save_error": "Fehler. Bitte versuche es erneut. Sie haben nur eine Stunde Zeit, nachdem Sie ein Passwort angefordert haben, um es zurückzusetzen.",
    "password_requirements": "Das Passwort muss zwischen 8 und 24 Zeichen lang sein, eine Zahl enthalten und sowohl Groß- als auch Kleinbuchstaben enthalten. Keine Leerzeichen erlaubt.",
    "updating": "Aktualisierung"
};