import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

import Nav from '../Nav'
import PrivacyCn from './translations/PrivacyCn';
import PrivacyCs from './translations/PrivacyCs';
import PrivacyDe from './translations/PrivacyDe';
import PrivacyEnUs from './translations/PrivacyEnUs';
import PrivacyEs from './translations/PrivacyEs';
import PrivacyFr from './translations/PrivacyFr';
import PrivacyIt from './translations/PrivacyIt';
import PrivacyJp from './translations/PrivacyJp';
import PrivacyTh from './translations/PrivacyTh';

export default function Privacy() {
  const { lang } = useParams();

  return (
    <>
      <Nav />

      <div className="legal-container">
        {(() => {
            switch (lang) {
              case 'en-us':
                return <PrivacyEnUs />
              case 'es':
                return <PrivacyEs />
              case 'cs':
                return <PrivacyCs />
              case 'de':
                return <PrivacyDe />
              case 'fr':
                return <PrivacyFr />
              case 'it':
                return <PrivacyIt />
              case 'jp':
                return <PrivacyJp />
              case 'th':
                return <PrivacyTh />
              case 'zh-cn':
                return <PrivacyCn />
              case 'cn':
                return <PrivacyCn />
              default:
                return <Redirect to="/privacy"/>
            }
          })()}
        </div>
    </>
  )
}
