import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

import TermsCs from './translations/TermsCs';
import TermsCn from './translations/TermsCn';
import TermsDe from './translations/TermsDe';
import TermsEnUs from './translations/TermsEnUs';
import TermsEs from './translations/TermsEs';
import TermsFr from './translations/TermsFr';
import TermsJp from './translations/TermsJp';
import TermsTh from './translations/TermsTh';
import TermsIt from './translations/TermsIt';
import Nav from '../Nav'

export default function Terms() {
  const { lang } = useParams();

  return (
    <>
      <Nav />

      <div className="legal-container">
        {(() => {
            switch (lang) {
              case 'en-us':
                return <TermsEnUs />
              case 'es':
                return <TermsEs />
              case 'cs':
                return <TermsCs />
              case 'de':
                return <TermsDe />
              case 'cn':
                return <TermsCn />
              case 'fr':
                return <TermsFr />
              case 'jp':
                return <TermsJp />
              case 'th':
                return <TermsTh />
              case 'it':
                return <TermsIt />
              default:
                return <Redirect to="/terms"/>
            }
          })()}
        </div>
    </>
  )
}
