export const translations = {
    "reset_password": "Redefinir senha",
    "new_password": "Nova Senha",
    "confirm_password": "Confirme a Senha",
    "submit": "Enviar",
    "error_password_min_length": "A senha deve ter pelo menos 8 caracteres",
    "error_password_max_length": "A senha não pode ter mais de 24 caracteres",
    "error_passwords_do_not_match": "As senhas não coincidem",
    "password_success": "Senha alterada com sucesso",
    "success_new_password_mobile": "Agora você pode fazer login com sua nova senha em seu aplicativo móvel.",
    "no_token_error": "Erro. Siga o link em seu e-mail de redefinição de senha.",
    "reset_password_save_error": "Erro. Por favor, tente novamente. Você só tem uma hora após solicitar uma senha para redefini-la.",
    "password_requirements": "A senha deve ter entre 8 e 24 caracteres, incluir um número, ter letras maiúsculas e minúsculas. Não são permitidos espaços.",
    "updating": "Atualizando"
};