import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyCn() {
  const termsUrl = buildTermsUrl('cn');
  const privacyUrl = buildPrivacyUrl('cn');
  const cppaUrl = buildCppaUrl()

  return (
<div>
  <h2>私政策</h2>

  <p>
    Alliance Laundry Systems LLC（以下简称“Alliance”、“本公司”、“我司”、“我们”）向您（术语“您”应指应用程序或网站的任何用户 [以下简称“用户”]）提供移动应用程序或相关网站（以下简称“应用程序”或“网站”），以便您享受我们的第三方洗衣服务提供商（以下简称“洗衣设施”）的洗衣服务（这些第三方服务统称“服务”）。 相关洗衣设施是您个人信息（如本文所定义）的控制者，Alliance 代表洗衣设施处理（如本文所定义）您的个人信息，并作为洗衣设施的处理者。
  </p>

  <p>
    本隐私政策的条款（以下简称“隐私政策”）解释了您数据的使用方式。 如果您通过应用程序或网站提供信息、下载、注册应用程序帐户（以下简称“帐户”）、使用应用程序的任何部分、提交信息或使用洗衣设施通过应用程序提供的服务，则应适用本隐私政策中概述的做法和政策。 本隐私政策旨在作为洗衣设施隐私政策的补充，如需进一步了解洗衣设施如何使用通过应用程序或网站收集到的您的个人信息，请参阅洗衣设施的政策。
  </p>

  <p>
    本文未定义的术语应采用 Alliance 服务条款中规定的含义，该文件详见 <a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a>，且这些术语已在本文中使用。
  </p>

  <div>
    <p>
      在遵守适用法律的情况下，我们可能随时修改本隐私政策，修改后的隐私政策一经在网站、应用程序上发布或通知您后立即生效。 请定期检查本隐私政策，确保您知悉任何修改。 如果您不接受修改版协议中所做的更改，则应立即停止一切使用本应用程序的行为。 您继续访问或使用本应用程序将被视为您最终接受修改后的隐私政策。
    </p>
  </div>

  <p>
    本修订后的隐私政策自 2021 年 4 月 1 日起生效。 欲了解我们如何出于我们的目的处理个人数据的相关信息，请参阅我们的其他隐私政策（管辖我们在更广泛业务领域中的做法），详见：<a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
  </p>

  <h3>
    本隐私政策涵盖的信息。
  </h3>

  <p>
    本隐私政策涵盖洗衣设施对 Alliance 代表洗衣设施并根据其指示所收集的您的个人信息予以使用行为（以下简称“处理”）。
  </p>

  <h3>
    收集您的信息
  </h3>

  <p>
    要访问应用程序、网站和洗衣设施的服务，您需要注册帐户。 您注册帐户时需要提供特定个人数据，也就是可用于识别您身份的信息（以下简称“个人信息”），具体方式有：
  </p>

  <p>
    (1)	直接在应用程序或网站中输入此类个人信息（例如在适用的情况下，您的职位、姓名、电子邮件地址、地址、电话号码、信用卡和其他财务信息、年龄、出生日期和特定偏好）；或
  </p>

  <p>
    (2)	允许我们从您的 Facebook 社交网站中的个人资料和/或您的 Google 登录信息中访问您的个人信息
  </p>

  <p>
    您有责任提供最新、完整、真实且准确的信息，包括个人信息，并保持更新这些信息。 如果您拒绝提供这些信息，这可能意味着洗衣设施无法为您提供服务。 Alliance 概不承担与个人信息不准确或不完整有关的任何问题或责任，无论是由于您未能更新此类个人信息还是其他原因均不例外。
  </p>

  <p>
    除个人信息外，我们还可能会收集您提供的以下信息（与您的个人信息统称为“信息”）：
  </p>

  <p>
    <ul>
      <li>
      特定的标准浏览器信息，如浏览器类型、IP 地址和访问时间；
      </li>

      <li>
      与您发送给我们的通信（如报告问题或提交查询）有关的信息；以及
      </li>

      <li>
    	交易历史记录。
      </li>
    </ul>
  </p>

  <p>
    除非根据任何适用法律的要求或洗衣设施的指示，否则我们不会保留您的个人信息。
  </p>

  <h3>
    使用您的信息。
  </h3>

  <p>
    洗衣设施可能会将您的个人信息用于多种合法目的，包括为了让洗衣设施可以为您提供服务。 更具体来说： 我们会根据洗衣设施的指示向其提供您的信息，以确保它们 (i) 根据您的偏好和兴趣显示量身定制的广告和内容 (ii) 履行服务以及 (iii) 响应支持请求。 的特定功能
  </p>

  <p>
    应用程序允许使用社交网络。 您应确保在使用这些功能时，不会提交任何不希望由其他用户发送、收集或使用的个人信息，如个人资料详细信息或电子邮件地址。
  </p>

  <p>
    洗衣设施还可能使用您的信息与您进行沟通，如向您提供有关服务的强制通知或酌情通知。 根据适用法律，洗衣设施还可能不时向您发送调查或促销邮件，告知您洗衣设施提供的其他产品或服务，除非您随时告知我们您不希望洗衣设施为此目的与您联系。
  </p>

  <h4>
    对于美国以外的用户
  </h4>

  <p>
    Alliance 总部位于美国，根据适用法律，Alliance 可能会将您的信息传输到美国，以便向洗衣设施提供您的信息。 若 Alliance 要将您的数据传输到您所在的国家/地区以外，则 Alliance 将确保遵照适用法律进行传输。
  </p>

  <p>
    如果我们将英国或欧洲经济区用户的信息传输给英国或欧洲经济区以外的地区，或者，如果我们将澳大利亚用户的信息传输到澳大利亚以外的地区，我们或他们将对这些数据的接收者施加合同义务，以保护您的信息，其中 (a) 对于英国或欧洲经济区用户，遵守英国或欧洲经济区要求的标准，或者 (b) 对于澳大利亚用户，遵守澳大利亚要求的标准。
  </p>

  <p>
    根据洗衣设施的任何指示，我们还可能要求任何接收者遵守旨在实现安全数据共享的国际框架。 对于由我们进行传输的情况，如果符合以下条件，我们也可能会传输您的信息： (a) 对于英国或欧洲经济区用户： (i) 传输到英国政府或欧盟委员会认为能够为您的信息提供充分保护的国家/地区；或者 (ii) Alliance 采取了适用法律中提及的“适当保障措施”之一；或者 (b) 对于澳大利亚用户： (i) 我们采取了合理措施确保这些信息的接收者不会违反 1988 年《隐私法》(Cth) 规定的澳大利亚隐私原则（以下简称“APP”）；或者 (ii) 我们对这些信息的接收者的行为或做法负责。
  </p>

  <h3>
    向第三方披露您的个人信息。
  </h3>

  <p>
    除本隐私政策另有规定外，您的个人信息将仅与洗衣设施、Alliance 及其控制的子公司和附属公司共享，您的个人信息不会在您不知情的情况下披露给任何其他第三方。
  </p>

  <p>
    如果我们与第三方共享您的信息，则任何此类披露都将始终遵守适用的数据保护法和洗衣设施发出的任何指示。 您可以访问您的帐户，选择与洗衣设施共享您的个人信息。
  </p>

  <p>
    洗衣设施可能会要求 Alliance 雇用其他公司向洗衣设施提供有限的管理和通信服务，如托管网站、处理和递送邮件、提供客户支持或提供信用卡处理服务。 Alliance 有权根据洗衣设施的指示向这些第三方公司披露您的个人信息，以便管理和维护网站和应用程序的功能、机能和运营，使洗衣设施能够向您提供服务。 这些公司仅被允许获取提供相应服务所必要的个人信息，除用于提供 Alliance 代表洗衣设施要求的这些服务外，洗衣设施不会授权这些公司使用您的个人信息。
  </p>

  <p>
    洗衣设施可能与之共享您个人信息的其他接收者或接收者类别包括： (i) 与法律义务或保护合法权利有关（如预防犯罪）；(ii) 可使用您个人信息验证您身份的反欺诈机构；(iii) 洗衣设施计划与之合并或被其收购的其他商业实体，或者洗衣设施与该实体进行重组；或者 (iv) 如果洗衣设施受到清算或接管，其任何相关继承者。
  </p>

  <p>
    我们没有强制要求质疑或争辩我们收到的任何搜查令、传票或其他类似政府要求的有效性。 在某些情况下，洗衣设施可能会选择购买或出售资产。 在这些类型的交易中，用户信息通常也是转让的业务资产之一。 此外，如果整个洗衣设施或其实质上的所有资产都被收购，则用户信息也将成为转让给第三方或被第三方收购的资产之一。
  </p>

  <h3>
    处理的法律依据。
  </h3>

  <p>
    本隐私政策描述了我们代表洗衣设施收集您信息的目的，以及洗衣设施可能使用您个人信息的目的。 洗衣设施可能依照以下法律依据之一处理您的信息；(a) 您已明确同意洗衣设施出于特定目的处理您的信息；(b) 处理是洗衣设施根据您与洗衣设施达成的协议履行其义务所必需的；(c) 处理是洗衣设施遵守法律（不包括合同义务）所必需的；(d)： 处理是保护他人生命安全所必需的；(e) 处理是洗衣设施履行公共利益的任务或洗衣设施官方职能所必需的，并且该任务或职能具有明确的法律依据；或者 (f) 处理是出于洗衣设施合法利益或第三方合法利益所必需的（除非有充分理由保护您的信息，且这些理由凌驾于这些合法利益之上）。 有关洗衣设施处理您数据所依照法律依据的更多详细信息，请参阅洗衣设施隐私政策。
  </p>

  <h3>
    您个人信息的安全性。
  </h3>

  <p>
     Alliance 使用行业范围内的标准做法，如防火墙、加密和（特定领域的）安全套接字层，根据洗衣设施的指示保护您的信息。 但是，尽管加密技术是有效的，但没有任何安全系统是不可逾越的。 我们会尽我们所能维护实体、电子和管理保护措施，以保护您的个人信息免受未经授权或不适当的访问，但 Alliance 无法绝对保证我们数据库的安全性，也无法保证您提供的信息通过互联网传输给 Alliance 时不会被截获。 Alliance 建议您不要向任何人透露您的密码。 如果我们代表洗衣设施通过您使用我们的应用程序和/或洗衣设施服务的行为来收集您的个人信息，则洗衣设施将仅在为完成所要求的活动所合理必要的时间内或法律要求的时间内保留这些信息。
  </p>

  <h3>
    自动收集的信息。
  </h3>

  <p>
    每次您与应用程序和/或服务交互时，我们都可能会（代表洗衣设施）接收和存储特定类型的信息。 洗衣设施可能会指示我们自动接收和记录您的浏览器中我们服务器日志上的信息，包括您的 IP 地址、Cookie 信息和您请求的页面。
  </p>

  <p>
    此外，洗衣设施还可能会使用第三方分析提供商和产品来获取、编译和分析有关用户如何使用服务以及如何与之交互的信息。 根据洗衣设施的指示，我们还可能会向这些分析提供商提供信息，以获取有关用户如何使用洗衣设施以及如何与之交互的统计数据和其他信息。 这些分析提供商可能会使用各种已有或新开发的追踪技术或工具（包括但不限于 Cookie、网络信标、HTTP 缓存、本地共享对象和永久标识符）来识别您的计算机或设备和/或收集或编译这些信息。 Alliance 无法控制向洗衣设施提供分析产品和服务的第三方技术、工具或做法。 有关洗衣设施自动收集哪些信息的更多信息，请参阅洗衣设施隐私政策。
  </p>

  <h3>
    收集和使用儿童个人信息。
  </h3>

  <p>
    应用程序和服务面向全体用户，Alliance 不会故意向儿童收集任何个人信息。 在帐户注册过程中，Alliance 需要收集年龄信息，如果用户自称未满 13 岁，Alliance 将阻止此类用户提供个人信息。
  </p>

  <h3>
    您对个人信息享有的权利。
  </h3>

  <p>
    根据您所在司法管辖区的不同，您可能对个人数据享有特定的权利，包括以下权利： (i) 了解您个人数据的处理情况（即本隐私政策和洗衣设施隐私政策中提供的信息）；(ii) 要求查阅洗衣设施持有的有关您的任何个人数据或索取其副本；(iii) 如果您认为您的个人数据不准确，可要求更正；(iv) 要求删除您的个人数据；(v) 拒绝出于特定目的处理您的个人数据，或要求洗衣设施停止使用您的信息；(vi) 要求不得采用基于自动处理的决定，并要求如果根据您的个人数据对您进行分析，则采取保障措施；(vii) 要求我们在技术上可行的情况下，向您或其他服务提供商或第三方传输您的个人数据副本；以及 (viii) 向洗衣设施发送电子邮件，随时要求洗衣设施停止或开始向您发送营销信息。 根据您所在司法管辖区的不同，上述权利中的部分或全部可能会受到某种限制。 此外，请注意，洗衣设施发送给您的每封电子邮件都将包含有关如何取消订阅的详细信息。 请注意，处理您的请求最多可能需要 28 天。
  </p>

  <p>
    如果您希望行使与您的个人信息有关的任何权利，请联系洗衣设施。 如果您愿意，也可以先联系我们：softwaresupport@alliancels.com，我们将通知洗衣设施。 这些电子邮件主题应标注“数据主体权利”。 洗衣设施将根据数据保护法处理此类请求。
  </p>

  <h3>
    关于本隐私政策的疑问或意见。
  </h3>

  <p>
    如果您对您个人信息的使用或本隐私政策有任何疑问或意见，请联系洗衣设施。 或者，您也可以联系 softwaresupport@alliancels.com，我们会将您的询问转给相关洗衣设施。
  </p>

  <p>
    如果您位于欧盟境内，当您对洗衣设施使用您的信息有任何疑虑，您也有权向您所在国家/地区的数据保护监管机构提出投诉。 有关详细联系方式，请参阅： https://edpb.europa.eu/about-edpb/board/members_en。
  </p>

  <p>
    如果您位于英国境内，当您对信息的使用有任何疑虑，您有权向信息专员办公室提出投诉。 有关详细联系方式，请参阅： https://ico.org.uk/global/contact-us/。
  </p>

  <p>
    如果您位于澳大利亚，您已知悉我们和洗衣设施均受 APP 的约束。 如果您对本隐私政策或我们处理您个人信息的方式有任何疑问、疑虑或投诉，请联系上述地址，我们将审查您的投诉，并在合理期限内作出回应。 如果您对投诉或疑虑的处理不满意，可以联系澳大利亚信息专员办公室：
  </p>

    <p>
      Office of the Australian Information Commissioner
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      电话: 1300 363 992
      <br />
      电子邮件: enquiries@oaic.gov.au
      <br />
      If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
    </p>
  </div>
  )
}
