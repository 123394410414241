import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyEs() {
  const termsUrl = buildTermsUrl('es');
  const privacyUrl = buildPrivacyUrl('es');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>
      POLÍTICA DE PRIVACIDAD
      </h2>

      <p>
      Alliance Laundry Systems LLC (en adelante, "Alliance", "nosotros", us"nuestro"), proporciona una aplicación móvil y un sitio web relacionado (en adelante, la "Aplicación" o el "Sitio") para usted (los términos "usted", "su" y "suyo" se referirán a cualquier usuario de la Aplicación o el Sitio (en adelante, los "Usuarios") para que pueda acceder a los servicios de lavandería de nuestros proveedores de lavandería externos (en adelante, la "Instalación de lavandería") (los servicios de terceros se denominan "Servicio" o "Servicios"). La Instalación de lavandería correspondiente es el controlador de su Información personal (como se define en este documento) y Alliance procesa (como se define en este documento) su Información personal en nombre de la Instalación de lavandería y como procesador de la Instalación de lavandería.
      </p>

      <p>
      Los términos de esta política de privacidad (en adelante, la "Política de privacidad") explican la forma en que se utilizan sus datos. Cuando proporcione información a través de la Aplicación o el Sitio, realice una descarga o se registre en una cuenta de la Aplicación (en adelante, una "Cuenta") utilizando cualquier parte de la Aplicación, enviando información o utilizando los Servicios proporcionados por la Instalación de lavandería a través de la Aplicación, se aplicarán las prácticas y políticas descritas en esta Política de privacidad. Esta Política de privacidad tiene por finalidad complementar la Política de privacidad de la Instalación de lavandería; consulte la política de la Instalación de lavandería para obtener más información sobre cómo utilizan su Información personal recopilada a través de la Aplicación o el Sitio.
      </p>

      <p>
      Los términos no definidos en este documento tendrán el significado que se proporciona en los Términos de servicio de Alliance, disponible en <a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> e incorporados aquí.
      </p>

      <blockquote>
      SUJETO A LA LEY APLICABLE, PODREMOS MODIFICAR ESTA POLÍTICA DE PRIVACIDAD EN CUALQUIER MOMENTO Y DICHA MODIFICACIÓN TENDRÁ VIGENCIA INMEDIATA AL PUBLICAR LA POLÍTICA DE PRIVACIDAD MODIFICADA EN EL SITIO, EN LA APLICACIÓN O AL NOTIFICARLO. REVISE ESTA POLÍTICA DE PRIVACIDAD PERIÓDICAMENTE PARA ASEGURARSE DE ESTAR INFORMADO RESPECTO DE CUALQUIER MODIFICACIÓN. SI NO ACEPTA LOS CAMBIOS REALIZADOS EN EL ACUERDO MODIFICADO, DEBE DEJAR DE UTILIZAR LA APLICACIÓN EN SU TOTALIDAD DE INMEDIATO. SE CONSIDERARÁ SU ACCESO O USO CONTINUADOS DE LA APLICACIÓN COMO ACEPTACIÓN CONCLUSIVA DE LA POLÍTICA DE PRIVACIDAD MODIFICADA.
      </blockquote>

      <p>
      Esta Política de privacidad revisada entra en vigencia a partir del 1 de abril de 2021. Para obtener información sobre cómo manejamos los datos personales para nuestros propios fines, consulte nuestras otras políticas de privacidad (que regulan nuestras prácticas en relación con el negocio en general) disponibles aquí: <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
      INFORMACIÓN CUBIERTA EN ESTA POLÍTICA DE PRIVACIDAD.
      </h3>

      <p>
      Esta Política de privacidad aborda el uso que la Instalación de lavandería hace de su Información personal que Alliance recopila en nombre de la Instalación de lavandería (en adelante, los "Procesos”) y bajo sus instrucciones.
      </p>

      <h3>
      RECOPILACIÓN DE SU INFORMACIÓN.
      </h3>

      <p>
      Para acceder a la Aplicación, el Sitio y los Servicios de la Instalación de lavandería, se le pedirá que se registre para obtener una Cuenta. Puede registrarse para obtener una Cuenta proporcionando ciertos datos personales, lo que significa información que se puede utilizar para identificarlo (en adelante, "Informacion personal"), ya sea al:
      </p>

      <p>
      (1) ingresar dicha Información personal (como, y según corresponda, su título, nombre, dirección de correo electrónico, direcciones, número de teléfono, tarjeta de crédito y otra información financiera, edad, fecha de nacimiento y ciertas preferencias) directamente en la Aplicación o en el Sitio; o
      </p>

      <p>
      (2) permitiéndonos acceder a su Información personal desde su perfil en el sitio de redes sociales de Facebook y/o su información de inicio de sesión de Google.
      </p>

      <p>
      Es su responsabilidad brindar información actualizada, completa, veraz y precisa, incluida la Información personal, y mantener dicha información actualizada. Si no proporciona la información, esto puede significar que la Instalación de lavandería no pueda brindarle los Servicios. Alliance no será responsable de ningún problema o responsabilidad relacionados con Información personal inexacta o incompleta, ya sea debido a que no actualizó dicha Información personal o por cualquier otro motivo.
      </p>

      <p>
      Además de la Información personal, podemos recopilar la siguiente información que usted proporciona (en conjunto con su Información personal, la "Información"):
      </p>

      <p>
        <ul>
          <li>
          Cierta información estándar del navegador, como tipo de navegador, dirección IP y tiempos de acceso;
          </li>

          <li>
          Información relacionada con las comunicaciones que nos envía (por ejemplo, para informar un problema o enviar consultas); e
          </li>

          <li>
          Historial de transacciones.
          </li>
        </ul>
      </p>

      <p>
      No retendremos su Información personal a menos que así lo exija cualquier ley aplicable o según las instrucciones de la Instalación de lavandería.
      </p>

      <h3>
      USO DE SU INFORMACIÓN.
      </h3>

      <p>
      La Instalación de lavandería puede utilizar su Información personal para una serie de fines legítimos, incluso para que la Instalación de lavandería pueda proporcionarle los Servicios. Más específicamente: proporcionamos su Información a las Instalaciones de lavandería según sus instrucciones para permitirles (i) mostrar publicidad y contenido que se adapten a sus preferencias e intereses; (ii) operar los Servicios; y (iii) responder a las solicitudes de soporte. Ciertas funciones de
      </p>

      <p>
      la Aplicación permiten el uso de redes sociales. Al utilizar estas funciones, debe asegurarse de no enviar ninguna Información personal que no desee que otros usuarios envíen, recopilen o utilicen, como los detalles de su perfil o su dirección de correo electrónico.
      </p>

      <p>
      La Instalación de Lavandería también puede usar su Información para comunicarse con usted, por ejemplo, para proporcionarle avisos obligatorios o avisos discrecionales sobre los Servicios. De vez en cuando, de acuerdo con la legislación aplicable, la Instalación de lavandería también puede enviarle encuestas o correos promocionales para informarle sobre otros productos o servicios disponibles en la Instalación de lavandería, a menos que nos informe en cualquier momento que no desea ser contactado para este propósito.
      </p>

      <h3>
      PARA USUARIOS FUERA DE LOS ESTADOS UNIDOS DE AMÉRICA
      </h3>

      <p>
      Alliance tiene su sede en los Estados Unidos de América y, sujeto a la ley aplicable, puede transferir su Información a los Estados Unidos de América para proporcionar su información a la Instalación de lavandería. En caso de que Alliance transfiera sus datos fuera de su país de origen, Alliance se asegurará de que la transferencia se realice de conformidad con la ley aplicable.
      </p>

      <p>
      Cuando transferimos Información de Usuarios en el Reino Unido o el EEE fuera del Reino Unido o el EEE, o, en el caso de Usuarios en Australia, fuera de Australia, nosotros o ellos impondremos obligaciones contractuales a los destinatarios de esos datos para proteger su información (a) en el caso de Usuarios en el Reino Unido o el EEE, el estándar requerido en el Reino Unido o el EEE, o, (b) en el caso de Usuarios en Australia, Australia.
      </p>

      <p>
      También podemos requerir, de acuerdo con las instrucciones de la Instalación de lavandería, que cualquier destinatario se suscriba a marcos internacionales destinados a permitir el intercambio seguro de datos. En el caso de transferencias por nuestra parte, también podremos transferir su información si: (a) en el caso de usuarios en el Reino Unido o el EEE: (i) la transferencia es a un país que el gobierno del Reino Unido o la Comisión Europea considera que brinda la protección adecuada de su información; o (ii) Alliance implementó una de las 'salvaguardas apropiadas' mencionadas en la legislación aplicable; o, (b) en el caso de Usuarios en Australia: (i) tomamos medidas razonables para garantizar que el destinatario de esa Información no infrinja los Principios de privacidad australianos (en adelante, la "APLICACIÓN") en virtud de la Ley de Privacidad de 1988 (Cth); o (ii) seguimos siendo responsables de los actos o prácticas del destinatario de esa información.
      </p>

      <h3>
      DIVULGACIÓN DE SU INFORMACIÓN PERSONAL A TERCEROS.
      </h3>

      <p>
      Excepto como se describe en esta Política de privacidad, su Información personal se compartirá solo con la Instalación de lavandería, Alliance y sus subsidiarias y afiliadas controladas, y su Información personal no se divulgará a ningún otro tercero sin su conocimiento.
      </p>

      <p>
      Si compartimos su información con terceros, dicha divulgación cumple en todo momento con la ley de protección de datos aplicable y las instrucciones emitidas por la Instalación de lavandería. Al acceder a su Cuenta, puede optar por compartir su Información personal con la Instalación de lavandería.
      </p>

      <p>
      La Instalación de lavandería puede solicitar a Alliance que contrate a otras empresas para que presten servicios administrativos y de comunicación limitados a la Instalación de lavandería, como alojar el Sitio, procesar y entregar correos, brindar atención al cliente o brindar servicios de procesamiento de tarjetas de crédito. Alliance tiene el derecho de divulgar su Información personal de acuerdo con las instrucciones de la Instalación de lavandería a estas empresas de terceros con el fin de administrar y mantener las características, funciones y operaciones del Sitio y la Aplicación para que la Instalación de lavandería pueda prestarle los servicios. A esas empresas se les permitirá obtener solo la Información personal que sea necesaria para que presten los servicios respectivos, en tanto que la Instalación de lavandería no autoriza a estas empresas a utilizar su Información personal, excepto con el único propósito de prestar los servicios solicitados por Alliance en nombre de la Instalación de lavandería.
      </p>

      <p>
      Otros destinatarios, o categorías de destinatarios, con los que la Instalación de lavandería puede compartir su Información personal incluyen: (i) en relación con obligaciones legales o protección de derechos legales (como la prevención del delito); (ii) agencias de prevención de fraude que pueden usarlo para verificar su identidad; (iii) otras entidades comerciales con las que la Instalación de lavandería planea fusionarse o ser adquirido por ellas, o si la Instalación de lavandería se somete a una reorganización con esa entidad; o (iv) cualquier sucesor en interés, en caso de liquidación o administración de la Instalación de lavandería.
      </p>

      <p>
      No estamos obligados a cuestionar ni impugnar la validez de ninguna orden de registro, citación u otra solicitud gubernamental similar que recibamos. En algunos casos, una Instalación de lavandería puede optar por comprar o vender activos. En este tipo de transacciones, la información del usuario suele ser uno de los activos comerciales que se transfieren. Además, si se adquiriera una Instalación de lavandería, o sustancialmente todos sus activos, la información del usuario sería uno de los activos transferidos o adquiridos por un tercero.
      </p>

      <h3>
      FUNDAMENTO LEGAL PARA EL PROCESAMIENTO.
      </h3>

      <p>
      En esta Política de privacidad, se describen los propósitos para los cuales recopilamos su Información en nombre de la Instalación de lavandería y para los cuales la Instalación de lavandería puede utilizar su Información personal. La Instalación de lavandería puede depender de una de las siguientes bases legales para procesar su información; (a) usted proporciona un consentimiento claro para que una Instalación de lavandería procese su información para un propósito específico; (b) el procesamiento es necesario para que la Instalación de lavandería cumpla con sus obligaciones en virtud de un acuerdo que usted tiene con la Instalación de lavandería, (c) el procesamiento es necesario para que la Instalación de lavandería cumpla con la ley (sin incluir las obligaciones contractuales); (d): el procesamiento es necesario para proteger la vida de alguien; (e) el procesamiento es necesario para que la Instalación de lavandería realice una tarea de interés público o para las funciones oficiales de la Instalación de lavandería, y la tarea o función tiene un fundamento claro en la ley; o (f) el procesamiento es necesario para los intereses legítimos de la Instalación de lavandería o los intereses legítimos de un tercero (a menos que exista una buena razón para proteger su Información que anule esos intereses legítimos). Para obtener más detalles sobre el fundamento legal en el que se basa la Instalación de lavandería para procesar sus datos, consulte la política de privacidad de la Instalación de lavandería.
      </p>

      <h3>
      SEGURIDAD DE SU INFORMACIÓN PERSONAL.
      </h3>

      <p>
      Alliance utiliza prácticas estándar en toda la industria, como firewalls, cifrado y (en ciertas áreas) capas de conexión segura para proteger su Información de acuerdo con las instrucciones de la Instalación de lavandería. Sin embargo, por muy eficaz que sea la tecnología de cifrado, ningún sistema de seguridad es infalible. Aunque mantenemos medidas de seguridad físicas, electrónicas y administrativas para proteger su Información personal del acceso no autorizado o inapropiado, Alliance no puede garantizar la seguridad de nuestra base de datos, ni podemos garantizar que la información que usted proporcione no será interceptada mientras se transmite a Alliance a través de Internet. Alliance recomienda que no revele su contraseña a nadie. Si recopilamos Información personal sobre usted en nombre de la Instalación de lavandería mediante el uso de nuestra Aplicación y/o los Servicios de la Instalación de lavandería, la Instalación de lavandería solo conservará dicha información durante el tiempo que sea razonablemente necesario para cumplir con la actividad solicitada, o según lo requiera la ley.
      </p>

      <h3>
      INFORMACIÓN RECOPILADA AUTOMÁTICAMENTE.
      </h3>

      <p>
      Podremos recibir y almacenar (en nombre de la Instalación de lavandería) ciertos tipos de información cada vez que interactúa con la Aplicación y/o el Servicio. La Instalación de lavandería puede indicarnos que recibamos y registremos automáticamente información en los registros de nuestro servidor desde su navegador, incluidas su dirección IP, información de cookies y la página que solicitó.
      </p>

      <p>
      Además, la Instalación de lavandería puede utilizar proveedores y productos de análisis de terceros para obtener, compilar y analizar Información sobre cómo los Usuarios utilizan e interactúan con los Servicios. En caso de recibir instrucciones por parte de una Instalación de lavandería, podremos proporcionar información a estos proveedores de análisis con el fin de obtener estadísticas y otra información sobre cómo los usuarios utilizan e interactúan con la Instalación de lavandería. Estos proveedores de análisis pueden utilizar una variedad de tecnologías o herramientas de seguimiento nuevas o establecidas (incluidas, entre otras, cookies, balizas web, caché HTTP, objetos compartidos locales e identificadores persistentes) para reconocer su computadora o dispositivo y/o recopilar o compilar esta información. Alliance no ejerce ningún tipo de control sobre las tecnologías, herramientas o prácticas de terceros que brindan productos y servicios de análisis a la Instalación de lavandería. Para obtener más información sobre qué información recopila automáticamente la Instalación de lavandería, consulte la política de privacidad de la Instalación de lavandería.
      </p>

      <h3>
      RECOPILACIÓN Y USO DE INFORMACIÓN PERSONAL DE NIÑOS.
      </h3>

      <p>
      La Aplicación y los Servicios están destinados a un público general y Alliance no recopila a sabiendas Información personal de niños. Durante el proceso de registro de una Cuenta, Alliance recopila información sobre la edad y, en caso de que un usuario se identifique como menor de 13 años, Alliance impedirá que dichos usuarios proporcionen Información personal.
      </p>

      <h3>
      SUS DERECHOS EN RELACIÓN CON SU INFORMACIÓN PERSONAL.
      </h3>

      <p>
      Dependiendo de su jurisdicción, puede tener ciertos derechos con respecto a sus datos personales, incluido el derecho a: (i) ser informado sobre el procesamiento de sus datos personales (es decir, la información proporcionada en esta Política de privacidad y la política de privacidad de la Instalación de lavandería); (ii) solicitar acceso o realizar una copia de cualquier dato personal que la Instalación de lavandería tenga sobre usted; (iii) solicitar la rectificación de sus datos personales, si considera que son inexactos; (iv) solicitar que se eliminen sus datos personales; (v) oponerse a que sus datos personales se procesen para un propósito en particular o solicitar que la Instalación de lavandería deje de usar su información; (vi) solicitar no estar sujeto a una decisión basada en el procesamiento automatizado y que se implementen salvaguardas si se define un perfil suyo en función de sus datos personales; (vii) pedirnos que le transfiramos una copia de sus datos personales a usted o a otro proveedor de servicios o tercero cuando sea técnicamente posible; y (viii) pedirle a la Instalación de lavandería que deje de enviarle mensajes de marketing o comience a enviarle mensajes de marketing en cualquier momento enviando un correo electrónico a la Instalación de lavandería. Según su jurisdicción, algunos o todos estos derechos pueden estar limitados de alguna manera. Tenga en cuenta que, además, cada correo electrónico que le envíe la Instalación de lavandería contendrá detalles sobre cómo puede darse de baja. Tenga en cuenta que puede tomar hasta 28 días para procesar su solicitud.
      </p>

      <p>
      Si desea ejercer alguno de sus derechos en relación con su Información personal, debe comunicarse con la Instalación de lavandería. Si lo prefiere, puede contactarnos a softwaresupport@alliancels.com y notificaremos a la Instalación de lavandería. Estos correos electrónicos deben ser marcados con el asunto "Derechos de los interesados". La Instalación de lavandería procesará dicha solicitud de acuerdo con la legislación de protección de datos.
      </p>

      <h3>
      PREGUNTAS O COMENTARIOS SOBRE ESTA POLÍTICA DE PRIVACIDAD.
      </h3>

      <p>
      Si tiene alguna pregunta o comentario sobre el uso de su Información personal o esta Política de privacidad, debe comunicarse con la Instalación de lavandería. Por otro lado, comuníquese con softwaresupport@alliancels.com y transmitiremos su consulta a la Instalación de lavandería correspondiente.
      </p>

      <p>
      Si se encuentra en la UE, en caso de tener alguna inquietud sobre el uso de su información por parte de la Instalación de lavandería, también tiene derecho a presentar una queja ante el regulador de protección de datos de su país. Para acceder a los detalles de contacto, consulte: https://edpb.europa.eu/about-edpb/board/members_en.
      </p>

      <p>
      Si se encuentra en el Reino Unido, en caso de tener alguna inquietud sobre el uso de su información, tiene derecho a presentar una queja ante la Oficina del Comisionado de Información. Para acceder a los detalles de contacto, consulte: https://ico.org.uk/global/contact-us/.
      </p>

      <p>
      Si se encuentra en Australia, reconoce que nosotros y la Instalación de lavandería estamos sujetos a las APLICACIONES. Si tiene alguna pregunta, inquietud o queja sobre esta Política de privacidad, o sobre cómo manejamos su Información personal, comuníquese a la dirección que se indica arriba; revisaremos su queja y le responderemos dentro de un período razonable. Si no está satisfecho con el manejo de su queja o inquietud, puede comunicarse con la Oficina del Comisionado de Información de Australia:
      </p>

      <p>
      Oficina del Comisionado de Información de Australia
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      Teléfono: 1300 363 992
      <br />
      Correo electrónico:  enquiries@oaic.gov.au
      <br />
      If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
      </p>

    </div>
  )
}
