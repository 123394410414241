import React, { useEffect } from 'react'
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom';
import Privacy from './Privacy'
import { brandHyphenated, brand } from '../../utils/brands'

export default function PrivacyContainer() {
  let { path, url } = useRouteMatch();

  useEffect(() => {
    document.title = "Privacy Policy"
  })

  return (
    <div >
      <Switch>
        <Route exact path={path}>
          <img className="brand-logo" src={`/brands/${brandHyphenated}.svg`} alt={brand} />

          <ul className="lang-selector-container">
            <h2>Privacy Policy</h2>

            <h3>
              <Link to={`/`}>
                Back
              </Link>
            </h3>
            
            <li>
              <Link to={`${url}/en-us`}>
                English
              </Link>
            </li>

            <li>
              <Link to={`${url}/cn`}>
                Chinese
                <span>中文</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/cs`}>
                Czech
                <span>Ceština</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/fr`}>
                French
                <span>Français</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/de`}>
                German
                <span>Deutsch</span>
              </Link>
            </li>
            
            <li>
              <Link to={`${url}/it`}>
                Italian
                <span>Italiano</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/jp`}>
                Japanese
                <span>日本語</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/es`}>
                Spanish
                <span>Español</span>
              </Link>
            </li>

            <li>
              <Link to={`${url}/th`}>
                Thai
                <span>าไทย</span>
              </Link>
            </li>
          </ul>
        </Route>

        <Route path={`${path}/:lang`}>
          <Privacy />
        </Route>
      </Switch>
    </div>

  )
}
