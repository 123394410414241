export const translations = {
    "reset_password": "Επαναφέρετε τον κωδικό πρόσβασης",
    "new_password": "Νέος Κωδικός",
    "confirm_password": "Επιβεβαίωση Κωδικού",
    "submit": "υποβάλλουν",
    "error_password_min_length": "Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες",
    "error_password_max_length": "Ο κωδικός πρόσβασης δεν μπορεί να είναι μεγαλύτερος από 24 χαρακτήρες",
    "error_passwords_do_not_match": "Οι κωδικοί πρόσβασης δεν ταιριάζουν",
    "password_success": "Ο κωδικός άλλαξε επιτυχώς",
    "success_new_password_mobile": "Τώρα μπορείτε να συνδεθείτε με τον νέο κωδικό πρόσβασης στην εφαρμογή σας για κινητά.",
    "no_token_error": "Λάθος. Ακολουθήστε τον σύνδεσμο στο email επαναφοράς κωδικού πρόσβασης.",
    "reset_password_save_error": "Λάθος. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα. Έχετε μόνο μία ώρα αφού ζητήσετε έναν κωδικό πρόσβασης για να τον επαναφέρετε.",
    "password_requirements": "Ο κωδικός πρόσβασης πρέπει να αποτελείται από 8 έως 24 χαρακτήρες, να περιλαμβάνει έναν αριθμό, να έχει κεφαλαία και πεζά γράμματα. Δεν επιτρέπονται κενά.",
    "updating": "Ενημέρωση"
};