export const translations = {
    "reset_password": "รีเซ็ตรหัสผ่าน",
    "new_password": "รหัสผ่านใหม่",
    "confirm_password": "ยืนยันรหัสผ่าน",
    "submit": "ส่ง",
    "error_password_min_length": "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
    "error_password_max_length": "รหัสผ่านต้องมีความยาวไม่เกิน 24 ตัวอักษร",
    "error_passwords_do_not_match": "รหัสผ่านไม่ตรงกัน",
    "password_success": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
    "success_new_password_mobile": "ตอนนี้คุณสามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่บนแอพมือถือของคุณ",
    "no_token_error": "ข้อผิดพลาด. โปรดไปที่ลิงก์ในอีเมลรีเซ็ตรหัสผ่านของคุณ",
    "reset_password_save_error": "ข้อผิดพลาด. กรุณาลองอีกครั้ง. คุณมีเวลาเพียงหนึ่งชั่วโมงหลังจากขอรหัสผ่านเพื่อรีเซ็ตรหัสผ่าน",
    "password_requirements": "รหัสผ่านต้องมีความยาวระหว่าง 8 ถึง 24 อักขระ รวมตัวเลข มีทั้งตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก ไม่อนุญาตให้มีช่องว่าง",
    "updating": "กำลังอัปเดต"
};