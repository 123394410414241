import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyIt() {
  const termsUrl = buildTermsUrl('it');
  const privacyUrl = buildPrivacyUrl('it');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>INFORMATIVA SULLA PRIVACY</h2>

      <p>
        Alliance Laundry Systems LLC ("Alliance","noi","a noi","il nostro"), fornisce un'applicazione per cellulari e un sito web correlato ("l'app" o il "sito") a te: i termini "tu" "tuo" si riferiscono a qualsiasi utente dell'app o del sito ("utenti") per poter accedere ai servizi di Lavanderia dei nostri fornitori di servizi di terze parti (la "Lavanderia") (tali servizi di soggetti terzi sono denominati "servizio" o "servizi"). La Lavanderia in questione è il titolare del trattamento delle tue Informazioni personali (come definite nel presente documento) e Alliance tratta (come definite nel presente documento) le tue Informazioni personali per conto della Lavanderia e come responsabile del trattamento per la Lavanderia.
      </p>

      <p>
        I termini di questa informativa sulla privacy ("Informativa sulla privacy") spiegano come vengono utilizzati i tuoi dati. Quando fornisci informazioni tramite l'app o il sito, scaricando, registrando un account sull'app (un "account"), utilizzando qualsiasi parte dell'app, inviando informazioni o utilizzando i servizi forniti dalla Lavanderia tramite l'app, si applicano le pratiche e le politiche descritte nella presente Informativa sulla privacy. La presente Informativa sulla privacy ha lo scopo di integrare quella della Lavanderia; consulta la politica della Lavanderia per ulteriori informazioni su come vengono utilizzate le tue Informazioni personali raccolte tramite l'app o il sito.
      </p>

      <p>
        I termini non definiti nel presente documento avranno il significato fornito nei termini di servizio di Alliance, disponibili all'indirizzo <a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> e qui registrati.
      </p>

      <blockquote>
        IN BASE ALLA LEGGE IN VIGORE POSSIAMO MODIFICARE LA PRESENTE INFORMATIVA SULLA PRIVACY IN QUALSIASI MOMENTO, E TALE MODIFICA AVRÀ EFFETTO IMMEDIATO DOPO LA PUBBLICAZIONE DELL'INFORMATIVA SULLA PRIVACY MODIFICATA SUL SITO, SULL'APP O DOPO AVERLA NOTIFICATA ALL'UTENTE. CONSULTA QUESTA INFORMATIVA SULLA PRIVACY PERIODICAMENTE, PER ASSICURARTI DI ESSERE A CONOSCENZA DI EVENTUALI MODIFICHE. SE NON ACCETTI LE MODIFICHE APPORTATE ALL'ACCORDO MODIFICATO, DEVI CESSARE IMMEDIATAMENTE L'UTILIZZO COMPLETO DELL'APP. L'ACCESSO O L'USO CONTINUATO DELL'APP SARÀ CONSIDERATO ACCETTAZIONE DEFINITIVA DELL'INFORMATIVA SULLA PRIVACY MODIFICATA.
      </blockquote>

      <p>
        La presente Informativa sulla privacy modificata è in vigore dal 1° aprile 2021. Per informazioni in relazione a come gestiamo i dati personali per i nostri scopi, consulta le nostre altre informative sulla privacy (che regolano le nostre pratiche in relazione alla nostra attività più ampia) che puoi trovare qui: <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
        INFORMAZIONI COPERTE NELLA PRESENTE INFORMATIVA SULLA PRIVACY.
      </h3>

      <p>
        La presente Informativa sulla privacy copre l'utilizzo da parte della Lavanderia delle Informazioni personali che Alliance raccoglie per conto e su istruzione della Lavanderia ("tratta").
      </p>

      <h3>
        RACCOLTA DELLE TUE INFORMAZIONI.
      </h3>

      <p>
        Per accedere all'app, al sito e ai servizi della Lavanderia, ti sarà richiesto di registrare un account. Puoi iscriverti a un account fornendo alcuni dati personali, il che significa che alcune informazioni potranno essere utilizzate per identificarti ("Informazioni personale"), mediante:
      </p>

      <p>
        (1)	l'inserimento di tali Informazioni personali (come, e se applicabile, titolo, nome, e-mail, indirizzi, numero di telefono, numero di carta di credito e altre informazioni finanziarie, età, data di nascita e determinate preferenze) direttamente nell'app o sul sito; oppure
      </p>

      <p>
        (2)	consentendoci l'accesso alle tue Informazioni personali dal profilo di Facebook e/o alle tue informazioni di accesso di Google.
      </p>

      <p>
        È tua responsabilità fornire informazioni aggiornate, e mantenerle aggiornate, complete, veritiere e accurate, comprese le Informazioni personali. Se non fornisci le informazioni, la Lavanderia potrebbe non essere in grado di fornirti i servizi. Alliance non avrà responsabilità né obblighi in merito a Informazioni personali inesatte o incomplete, dovute al mancato aggiornamento di tali Informazioni personali o altro motivo.
      </p>

      <p>
        Oltre alle Informazioni personali, possiamo raccogliere le seguenti informazioni da te fornite (collettivamente con le Informazioni personali, denominate "Informazioni"):
      </p>

      <p>
        <ul>
          <li>
          Alcune informazioni standard del browser, ad esempio il tipo di browser, l'indirizzo IP e gli orari di accesso;
          </li>

          <li>
          Informazioni relative alle comunicazioni che ci invii (ad esempio per segnalare un problema o per inviare domande); e
          </li>

          <li>
          Cronologia delle transazioni.
          </li>
        </ul>
      </p>

      <p>
        Non conserveremo le tue Informazioni personali a meno che non sia richiesto dalle legge in vigore o come indicato dalla Lavanderia in questione.
      </p>

      <h3>
        UTILIZZO DELLE TUE INFORMAZIONI.
      </h3>

      <p>
        La Lavanderia può utilizzare le tue Informazioni personali per una serie di scopi legittimi, inclusa lo modalità attraverso la quale la Lavanderia può fornirti i servizi. Più specificamente: forniamo le tue informazioni alla Lavanderia su sua indicazione per consentire di (i) visualizzare pubblicità e contenuti personalizzati in base alle tue preferenze e interessi, (ii) gestire i servizi e (iii) rispondere alle richieste di assistenza. Alcune caratteristiche
      </p>

      <p>
        dell'app offrono la possibilità di sfruttare il social networking. Quando utilizzi queste funzionalità, devi assicurarti di non inviare alcuna Informazione personale che non vuoi sia inviata, raccolta o utilizzata da altri utenti, ad esempio i dettagli del tuo profilo o il tuo indirizzo e-mail.
      </p>

      <p>
        La Lavanderia può utilizzare le tue informazioni anche per comunicare con te, ad esempio inviandoti comunicazioni obbligatorie o avvisi discrezionali sui servizi. Di volta in volta, in conformità con la legislazione in vigore, la Lavanderia può anche inviarti sondaggi o e-mail promozionali per informarti di altri suoi prodotti o servizi disponibili, a meno che tu non ci informi, in qualsiasi momento, che non desideri essere contattato a tal fine.
      </p>

      <h4>
        PER UTENTI AL DI FUORI DEGLI STATI UNITI D'AMERICA
      </h4>

      <p>
        Alliance ha sede negli Stati Uniti d'America e, in base alla legge in vigore, può trasferire le tue informazioni negli USA al fine di fornire le tue informazioni alla Lavanderia. Nel caso in cui Alliance trasferisca i tuoi dati al di fuori del tuo paese di origine, si assicurerà che il trasferimento venga effettuato in conformità con la normativa in vigore.
      </p>

      <p>
        Quando trasferiamo le informazioni degli utenti nel Regno Unito o nel SEE al di fuori del Regno Unito o del SEE o, nel caso di utenti in Australia, al di fuori dell'Australia, imporremo (noi o gli utenti) obblighi contrattuali ai destinatari di tali dati, in modo da proteggere le tue informazioni, (a) nel caso di utenti nel Regno Unito o nel SEE, secondo lo standard richiesto da Regno Unito o SEE, o (b) nel caso degli utenti in Australia, dall'Australia.
      </p>

      <p>
        Possiamo anche richiedere, in conformità con le istruzioni della Lavanderia, a qualsiasi destinatario di sottoscrivere quadri normativi internazionali intesi a consentire la condivisione sicura dei dati. Nel caso di trasferimenti da parte nostra, possiamo anche trasferire le tue informazioni se: (a) nel caso di utenti residenti nel Regno Unito o nel SEE: (i) il trasferimento avviene in un paese ritenuto in grado di fornire un'adeguata protezione delle tue informazioni da parte del governo del Regno Unito o della Commissione europea; o (ii) Alliance ha posto in essere una delle "garanzie appropriate" a cui si fa riferimento nella normativa in vigore; o, (b) nel caso di utenti in Australia: (i) adottiamo misure ragionevoli per garantire che il destinatario di tali Informazioni non violi i Principi australiani in materia di privacy ("APP") ai sensi del Privacy Act 1988 (Cth); o (ii) restiamo responsabili degli atti o delle pratiche del destinatario di tali informazioni.
      </p>

      <h3>
        DIVULGAZIONE DELLE TUE INFORMAZIONI PERSONALI A TERZI.
      </h3>

      <p>
        Ad eccezione di quanto descritto nella presente Informativa sulla privacy, le tue Informazioni personali saranno condivise solo con la Lavanderia, Alliance e le sue sussidiarie e affiliate controllate, inoltre le tue Informazioni personali non saranno divulgate a terzi a tua insaputa.
      </p>

      <p>
        Nel caso in cui condividessimo le tue informazioni con terze parti, qualsiasi divulgazione è sempre conforme alla legge sulla protezione dei dati in vigore e alle istruzioni emesse dalla Lavanderia. Accedendo al tuo account, puoi scegliere di condividere le tue Informazioni personali con la Lavanderia.
      </p>

      <p>
        La Lavanderia può richiedere ad Alliance di assumere altre società per fornire servizi amministrativi e di comunicazione limitati alla Lavanderia, come l'hosting del sito, l'elaborazione e la consegna di invii, l'assistenza ai clienti o servizi di elaborazione delle carte di credito. Alliance ha il diritto di divulgare le tue Informazioni personali in conformità con le istruzioni della Lavanderia a queste società terze allo scopo di amministrare e mantenere le caratteristiche, le funzioni e le operazioni del sito e dell'app, in modo che la Lavanderia possa fornirti i servizi. A tali società sarà consentito di ottenere solo le Informazioni personali necessarie per fornire i rispettivi servizi, e la Lavanderia non autorizza tali società a utilizzare le tue Informazioni personali tranne che per il solo scopo di fornire i servizi richiesti da Alliance per conto della Lavanderia.
      </p>

      <p>
        Altri destinatari, o categorie di destinatari, con cui la Lavanderia può condividere le tue Informazioni personali includono: (I)in relazione agli obblighi di legge o alla tutela dei diritti legali (ad esempio la prevenzione del crimine); (ii) le agenzie di prevenzione delle frodi, che possono utilizzarle per verificare la tua identità; (iii) altre entità commerciali con cui la Lavanderia intende fondersi o venire acquisita, o se la Lavanderia subisce una riorganizzazione con tale entità; o (iv) qualsiasi successore interessato, in caso di liquidazione o amministrazione della Lavanderia.
      </p>

      <p>
        Non siamo tenuti a mettere in discussione o a contestare la validità di alcun mandato di perquisizione, citazione in giudizio o altra richiesta governativa simile che riceviamo. In alcuni casi, la Lavanderia può scegliere di acquistare o di vendere beni. In questi tipi di transazioni, le informazioni dell'utente sono in genere uno dei beni aziendali che vengono trasferiti. Inoltre, se una Lavanderia, o sostanzialmente tutti i suoi beni, venisse acquisita, le informazioni dell'utente sarebbero una delle risorse che vengono trasferite o acquisite dalla terza parte.
      </p>

      <h3>
        BASE GIURIDICA PER IL TRATTAMENTO.
      </h3>

      <p>
        La presente Informativa sulla privacy ha descritto le finalità per le quali raccogliamo le tue informazioni per conto della Lavanderia e per le quali la Lavanderia può utilizzarle. La Lavanderia può fare riferimento a una delle seguenti basi legali per trattare le tue informazioni; (a) hai fornito un chiaro consenso affinché la Lavanderia tratti le tue informazioni per uno scopo specifico; (b) il trattamento è necessario affinché la Lavanderia adempia ai propri obblighi ai sensi di un contratto stipulato con la Lavanderia stessa, (c) il trattamento è necessario affinché la Lavanderia rispetti la legge (esclusi gli obblighi contrattuali); (d): il trattamento è necessario per proteggere la vita di una persona; (e) il trattamento è necessario affinché la Lavanderia possa svolgere un compito di interesse pubblico o per le funzioni ufficiali della Lavanderia e il compito o la funzione abbiano una chiara base giuridica; o (f) il trattamento è necessario per gli interessi legittimi della Lavanderia o per gli interessi legittimi di una terza parte, (a meno che non vi sia una buona ragione per proteggere le tue informazioni che prevalga su tali interessi legittimi). Per maggiori dettagli sulla base legale alla quale si riferisce Lavanderia per trattare i tuoi dati, consulta L'informativa sulla privacy della Lavanderia.
      </p>

      <h3>
        SICUREZZA DELLE TUE INFORMAZIONI PERSONALI.
      </h3>

      <p>
        Alliance utilizza pratiche standard a livello di settore come firewall, crittografia e (in alcune aree) il protocollo SSL per proteggere le tue informazioni, in conformità con le istruzioni della Lavanderia. Tuttavia, per quanto efficace sia la tecnologia di crittografia, nessun sistema di sicurezza è impenetrabile. Sebbene manteniamo garanzie fisiche, elettroniche e amministrative per proteggere le tue Informazioni personali da accessi non autorizzati o inappropriati, Alliance non può garantire la sicurezza del nostro database, né possiamo garantire che le informazioni fornite non verranno intercettate mentre vengono trasmesse, via Internet, ad Alliance. Alliance consiglia di non rivelare la password a nessuno. Se raccogliamo Informazioni personali su di te per conto della Lavanderia tramite la nostra app e/o tramite i servizi, la Lavanderia conserverà tali informazioni solo per il tempo ragionevolmente necessario per adempiere all'attività richiesta, o secondo le richieste legali.
      </p>

      <h3>
        INFORMAZIONI RACCOLTE AUTOMATICAMENTE.
      </h3>

      <p>
        Possiamo ricevere e conservare (per conto della Lavanderia) determinati tipi di informazioni ogni volta che interagisci con l'app e/o con il servizio. Possiamo ricevere istruzioni dalla Lavanderia per ricevere e registrare automaticamente le informazioni sui log del nostro server dal tuo browser, inclusi indirizzo IP, informazioni sui cookie e la pagina che hai richiesto.
      </p>

      <p>
        Inoltre, la Lavanderia può servirsi di analisti e prodotti di terze parti per ottenere, compilare e analizzare le informazioni sull'utilizzo e l'interazione con i servizi da parte degli utenti. In caso di richiesta dalla Lavanderia, possiamo fornire informazioni a questi analisti allo scopo di avere statistiche e altre informazioni sull'utilizzo e l'interazione con la Lavanderia da parte degli utenti. Tali analisti possono utilizzare diverse tecnologie o strumenti di tracciamento consolidati o nuovi (inclusi, ma non limitati a, cookie, web beacon, cache HTTP, oggetti locali condivisi e identificatori univoci) per riconoscere il tuo computer o dispositivo e/o per raccogliere o compilare queste informazioni. Alliance non ha alcun controllo sulle tecnologie, strumenti o pratiche di terze parti che forniscono prodotti e servizi di analisi alla Lavanderia. Per ulteriori informazioni su quali informazioni la Lavanderia raccoglie automaticamente, consulta l'Informativa sulla privacy della Lavanderia.
      </p>

      <h3>
        RACCOLTA E UTILIZZO DELLE INFORMAZIONI PERSONALI DI MINORI.
      </h3>

      <p>
        L'app e i servizi sono destinati a un pubblico generico e Alliance non raccoglie consapevolmente alcuna informazione personale da minori. Durante il processo di registrazione dell'account, Alliance raccoglie informazioni sull'età, e se gli utenti si identificano come minori di 13 anni, Alliance impedirà a tali utenti di fornire Informazioni personali.
      </p>

      <h3>
        I TUOI DIRITTI SULLE TUE INFORMAZIONI PERSONALI.
      </h3>

      <p>
        A seconda della tua giurisdizione, hai determinati diritti sui tuoi dati personali, incluso il diritto a: (i) venire informato sul trattamento dei tuoi dati personali (ovvero sulle informazioni fornite nella presente Informativa sulla privacy e l'informativa sulla privacy della Lavanderia); (ii) richiedere l'accesso ai dati personali che la Lavanderia detiene su di te o a una copia; (iii) richiedere la rettifica dei tuoi dati personali, se ritieni che siano inesatti; (iv) richiedere la cancellazione dei tuoi dati personali; (v) opporti al trattamento dei dati personali per uno scopo particolare o richiedere che la Lavanderia interrompa l'utilizzo delle tue informazioni; (vi) richiedere di non essere soggetto a una decisione basata sul trattamento automatizzato e di disporre di garanzie in caso di profilazione sulla base dei tuoi dati personali; (vii) richiedere il trasferimento di una copia dei tuoi dati personali a te o a un altro fornitore di servizi o a terzi, ove tecnicamente fattibile; e (viii) chiedere alla Lavanderia di interrompere o di iniziare l'invio di messaggi pubblicitari in qualsiasi momento, inviando un'e-mail alla Lavanderia. A seconda della tua giurisdizione, alcuni o tutti questi diritti potrebbero essere in qualche modo limitati. Tieni presente che, inoltre, ogni messaggio di posta elettronica inviato dalla Lavanderia conterrà dettagli su come annullare l'iscrizione. Tieni presente che per dare seguito alla tua richiesta potrebbero essere necessari fino a 28 giorni.
      </p>

      <p>
        Se desideri esercitare uno dei tuoi diritti in relazione alle tue Informazioni personali, contatta la Lavanderia. Se preferisci, puoi contattarci su softwaresupport@alliancels.com e informeremo la Lavanderia. Queste e-mail devono avere nell'oggetto il titolo "Diritti dell'interessato al trattamento". La Lavanderia tratterà tale richiesta in conformità con la legislazione sulla protezione dei dati.
      </p>

      <h3>
        DOMANDE O COMMENTI RIGUARDANTI LA PRESENTE INFORMATIVA SULLA PRIVACY.
      </h3>

      <p>
        In caso di domande o commenti sull'uso delle Informazioni personali o sulla presente Informativa sulla privacy, devi contattare la Lavanderia. In alternativa, contatta softwaresupport@alliancels.com e inoltreremo la tua richiesta alla Lavanderia in questione.
      </p>

      <p>
        Se risiedi nell'UE, in caso di dubbi sull'utilizzo delle tue informazioni da parte della Lavanderia, hai anche il diritto di presentare un reclamo all'autorità di regolamentazione della protezione dei dati nel tuo paese. Per i dettagli di contatto, consulta il sito: https://edpb.europa.eu/about-edpb/board/members_en.
      </p>

      <p>
        Se risiedi nel Regno Unito, in caso di dubbi sull'utilizzo delle tue informazioni, hai il diritto di presentare un reclamo all'Information Commissioner’s Office. Per i dettagli di contatto, consulta il sito: https://ico.org.uk/global/contact-us/.
      </p>

      <p>
        Se risiedi in Australia, confermi di essere al corrente che noi e la Lavanderia siamo vincolati dalle APP. In caso di domande, dubbi o reclami sulla presente Informativa sulla privacy o su come gestiamo le tue Informazioni personali, contatta l'indirizzo sopra indicato, esamineremo il tuo reclamo e risponderemo entro un periodo di tempo ragionevole. Se non sei soddisfatto della gestione del tuo reclamo o della tua preoccupazione, puoi contattare l'Information Commissioner’s Office in Australia:
      </p>

      <p>
        Office of the Australian Information
        <br />
        GPO Box 5218
        <br />
        Sydney NSW 2001
        <br />
        Telefono: 1300 363 992
        <br />
        Mail: enquiries@oaic.gov.au
        <br />
        If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
      </p>
    </div>
  )
}
