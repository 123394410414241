export const translations = {
    "reset_password": "パスワードリセット",
    "new_password": "新しいパスワード",
    "confirm_password": "新しいパスワードの確認",
    "submit": "OK",
    "error_password_min_length": "パスワードは8文字以上である必要があります",
    "error_password_max_length": "パスワードは24文字を超えることはできません",
    "error_passwords_do_not_match": "パスワードが一致していません",
    "password_success": "パスワード変更完了",
    "success_new_password_mobile": "新しいパスワードでログインしてください",
    "no_token_error": "エラー。パスワードのリセットメールに記載されているリンクをたどってください。",
    "reset_password_save_error": "エラー。もう一度やり直してください。パスワードを要求してからリセットするのに1時間しかありません。",
    "password_requirements": "パスワードは8〜24文字で、数字を含め、大文字と小文字の両方を使用する必要があります。スペースは許可されていません。",
    "updating": "更新中"
};