export const translations = {
    "reset_password": "Menetapkan semula kata laluan",
    "new_password": "Kata laluan baharu",
    "confirm_password": "Sahkan Kata Laluan",
    "submit": "Hantar",
    "error_password_min_length": "Kata laluan mestilah sekurang-kurangnya 8 aksara",
    "error_password_max_length": "Kata laluan tidak boleh melebihi 24 aksara",
    "error_passwords_do_not_match": "Kata laluan tidak sepadan",
    "password_success": "Kata laluan berjaya diubah",
    "success_new_password_mobile": "Sekarang anda boleh log masuk dengan kata laluan baru anda di aplikasi mudah alih anda.",
    "no_token_error": "Ralat. Ikuti pautan dalam e-mel kata laluan tetapan semula anda.",
    "reset_password_save_error": "Ralat. Sila cuba lagi. Anda hanya mempunyai satu jam setelah meminta kata laluan untuk menetapkannya semula.",
    "password_requirements": "Kata laluan mestilah antara 8 dan 24 aksara, sertakan nombor, mempunyai kedua-dua huruf besar dan kecil. Tiada ruang dibenarkan.",
    "updating": "Mengemas kini"
};