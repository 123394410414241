export const translations = {
    "reset_password": "Restablecer la contraseña",
    "new_password": "Nueva contraseña",
    "confirm_password": "Confirmar Contraseña",
    "submit": "Enviar",
    "error_password_min_length": "La contraseña debe tener al menos 8 caracteres",
    "error_password_max_length": "La contraseña no puede tener más de 24 caracteres",
    "error_passwords_do_not_match": "Las contraseñas no coinciden",
    "password_success": "Contraseña cambiada con éxito",
    "success_new_password_mobile": "Ahora puede iniciar sesión con su nueva contraseña en su aplicación móvil.",
    "no_token_error": "Error. Siga el enlace en su correo electrónico para restablecer la contraseña.",
    "reset_password_save_error": "Error. Inténtalo de nuevo. Solo tiene una hora después de solicitar una contraseña para restablecerla.",
    "password_requirements": "La contraseña debe tener entre 8 y 24 caracteres, incluir un número, tener letras mayúsculas y minúsculas. No se permiten espacios.",
    "updating": "Actualizando"
};