const brands = {
  'sq': 'Speed Queen',
  'hu': 'Huebsch',
  'pr': 'Primus',
  'ip': 'IPSO',
  'un': 'UniMac'
}

const brandLogos = {
  'sq': 'speed-queen',
  'hu': 'huebsch',
  'pr': 'primus',
  'ip': 'ipso',
  'un': 'unimac'
}

export const brand = brands[process.env.REACT_APP_BRAND.toLowerCase()];
export const brandAbbrev = process.env.REACT_APP_BRAND.toLowerCase();
export const brandHyphenated = brandLogos[process.env.REACT_APP_BRAND.toLowerCase()];

export function buildPrivacyUrl(lang) {
  return `http://${brandAbbrev}.alsgtg.com/privacy/${lang}/`
}

export function buildTermsUrl(lang) {
  return `http://${brandAbbrev}.alsgtg.com/terms/${lang}/`
}

export function buildCppaUrl() {
  return `https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/cppa/${brandAbbrev}_ccpa.html`
}