import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'

import { translations as TRANSLATIONS_CS } from './translations/cs'
import { translations as TRANSLATIONS_DE } from './translations/de'
import { translations as TRANSLATIONS_EL } from './translations/el'
import { translations as TRANSLATIONS_EN } from './translations/en'
import { translations as TRANSLATIONS_ES_419 } from './translations/es_419'
import { translations as TRANSLATIONS_ES_CO } from './translations/es_CO'
import { translations as TRANSLATIONS_FR } from './translations/fr'
import { translations as TRANSLATIONS_HU } from './translations/hu'
import { translations as TRANSLATIONS_ID } from './translations/id'
import { translations as TRANSLATIONS_IT } from './translations/it'
import { translations as TRANSLATIONS_JA } from './translations/ja'
import { translations as TRANSLATIONS_KO } from './translations/ko'
import { translations as TRANSLATIONS_MS } from './translations/ms'
import { translations as TRANSLATIONS_NL } from './translations/nl'
import { translations as TRANSLATIONS_PL } from './translations/pl'
import { translations as TRANSLATIONS_PT } from './translations/pt'
import { translations as TRANSLATIONS_RU } from './translations/ru'
import { translations as TRANSLATIONS_SO } from './translations/so'
import { translations as TRANSLATIONS_TH } from './translations/th'
import { translations as TRANSLATIONS_ZH_CN } from './translations/zh_CN'
import { translations as TRANSLATIONS_ZH_TW } from './translations/zh_TW'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: { translation: TRANSLATIONS_EN },
      zh_CN: { translation: TRANSLATIONS_ZH_CN },
      zh_TW: { translation: TRANSLATIONS_ZH_TW },
      cs: { translation: TRANSLATIONS_CS },
      nl: { translation: TRANSLATIONS_NL },
      fr: { translation: TRANSLATIONS_FR },
      de: { translation: TRANSLATIONS_DE },
      el: { translation: TRANSLATIONS_EL },
      hu: { translation: TRANSLATIONS_HU },
      id: { translation: TRANSLATIONS_ID },
      it: { translation: TRANSLATIONS_IT },
      ja: { translation: TRANSLATIONS_JA },
      jp: { translation: TRANSLATIONS_JA },
      jpn: { translation: TRANSLATIONS_JA },
      ko: { translation: TRANSLATIONS_KO },
      ms: { translation: TRANSLATIONS_MS },
      pl: { translation: TRANSLATIONS_PL },
      pt: { translation: TRANSLATIONS_PT },
      ru: { translation: TRANSLATIONS_RU },
      so: { translation: TRANSLATIONS_SO },
      es_co: { translation: TRANSLATIONS_ES_CO },
      es: { translation: TRANSLATIONS_ES_419 },
      th: { translation: TRANSLATIONS_TH },
    }
  });

export default i18n;
