export const translations = {
    "reset_password": "Dib u celi Password",
    "new_password": "Furaha Cusub",
    "confirm_password": "Xaqiiji Furaha",
    "submit": "Gudbi",
    "error_password_min_length": "Erayga sirta ah waa inuu ahaadaa ugu yaraan 8 xaraf",
    "error_password_max_length": "Erayga sirdoonku kama badnaan karo 24 xaraf",
    "error_passwords_do_not_match": "Furaha sirta ahi isma waafaqaan",
    "password_success": "Furaha sirta si guul leh ayaa loo beddelay",
    "success_new_password_mobile": "Hadda waxaad ku geli kartaa lambarkaaga sirta ah ee cusub moobilkaaga.",
    "no_token_error": "Khalad. Fadlan raac xiriirka ku jira iimaylkaaga sirta ah ee dib -u -dejinta.",
    "reset_password_save_error": "Khalad. Fadlan isku day mar kale. Waxaad haysataa hal saac oo kaliya kadib markaad codsato furaha sirta si aad dib ugu dejiso",
    "password_requirements": "Furaha waa inuu u dhexeeyaa 8 iyo 24 xaraf, oo uu ku jiro tiro, leh xarfo waaweyn iyo kuwo hoose labadaba. Meel banaan looma ogola",
    "updating": "Cusboonaysiinta"
};