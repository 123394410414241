import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyFr() {
  const termsUrl = buildTermsUrl('fr');
  const privacyUrl = buildPrivacyUrl('fr');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>
      POLITIQUE DE CONFIDENTIALITÉ
      </h2>

      <p>
      Alliance Laundry Systems LLC (« Alliance », « nous », « notre ») vous fournit (les termes « vous », « votre », « vos », « le vôtre » et « les vôtres » se réfèrent à tout utilisateur de l’Application et du Site [« les Utilisateurs »]) une application mobile et un site web associé (l’« Appli » ou le « Site »), afin que vous puissiez accéder aux services de blanchisserie de nos fournisseurs de blanchisserie tiers (la « Blanchisserie ») (ces services tiers étant appelés le « Service » ou les « Services »). La Blanchisserie concernée est responsable du contrôle de vos Données personnelles (telles que définies dans les présentes) et Alliance traite (comme défini dans les présentes) vos Données personnelles pour le compte de la Blanchisserie et en tant que sous-traitant de la Blanchisserie.
      </p>

      <p>
      Les termes de la présente politique de confidentialité (« Politique de confidentialité ») expliquent la manière dont vos données sont utilisées. Lorsque vous fournissez des informations par le biais de l’Application ou le Site, en téléchargeant, en vous inscrivant à un compte de l’Appli (un « Compte »), en utilisant n’importe quelle partie de l’Appli, en envoyant des informations ou en utilisant les Services fournis par la Blanchisserie par le biais de l’Appli, les pratiques et politiques décrites dans la présente Politique de confidentialité s’appliquent. Cette Politique de confidentialité est destinée à compléter la politique de confidentialité de la Blanchisserie ; pour plus d’informations sur la façon dont la Blanchisserie utilise vos Données personnelles collectées depuis l’Appli ou le Site, veuillez consulter la politique de la Blanchisserie.
      </p>

      <p>
      Les termes non définis dans les présentes auront la signification donnée dans les Conditions d’utilisation d’Alliance, disponibles à l’adresse <a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> et intégrées aux présentes.
      </p>

      <blockquote>
      SOUS RÉSERVE DE LA LÉGISLATION EN VIGUEUR, NOUS POUVONS MODIFIER CETTE POLITIQUE DE CONFIDENTIALITÉ À TOUT MOMENT ET CES MODIFICATIONS PRENDRONT EFFET IMMÉDIATEMENT À COMPTER DE LA PUBLICATION DE LA POLITIQUE DE CONFIDENTIALITÉ MODIFIÉE SUR LE SITE OU SUR L’APPLI, OU DE VOTRE NOTIFICATION. VEUILLEZ LIRE CETTE POLITIQUE DE CONFIDENTIALITÉ RÉGULIÈREMENT POUR VOUS ASSURER QUE VOUS AVEZ PRIS CONNAISSANCE DE TOUTES LES MODIFICATIONS. SI VOUS N’ACCEPTEZ PAS LES MODIFICATIONS APPORTÉES AU CONTRAT, VOUS DEVEZ CESSER IMMÉDIATEMENT TOUTE UTILISATION DE L’APPLI. VOTRE ACCÈS OU UTILISATION PERMANENTE DE L’APPLI SERA CONSIDÉRÉ(E) COMME UNE ACCEPTATION IMPLICITE DE LA POLITIQUE DE CONFIDENTIALITÉ MODIFIÉE.
      </blockquote>

      <p>
      Cette Politique de confidentialité révisée entre en vigueur le 1er avril 2021. Pour plus d’informations sur la manière dont nous traitons les données personnelles à des fins propres, veuillez consulter nos autres politiques de confidentialité (régissant nos pratiques en relation avec nos activités au sens large) ici : <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
      DONNÉES COUVERTES PAR LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ.
      </h3>

      <p>
      Cette Politique de confidentialité couvre l’utilisation, par la Blanchisserie, de vos Données personnelles recueillies par Alliance pour le compte de la Blanchisserie et sous ses instructions (« Procédures »).
      </p>

      <h3>
      COLLECTE DE VOS DONNÉES.
      </h3>

      <p>
      Afin d’accéder à l’Appli, au Site et aux Services de la Blanchisserie, vous devrez ouvrir un Compte. Vous pouvez ouvrir ce Compte en fournissant certaines données personnelles, c’est-à-dire des informations qui peuvent être utilisées pour vous identifier (« Données personnelles »), ou de la manière suivante:
      </p>

      <p>
      (1) en saisissant ces Données personnelles (telles que, et le cas échéant, votre titre, votre nom, votre adresse e-mail, vos adresses, votre numéro de téléphone, votre carte de crédit et d’autres informations financières, votre âge, votre date de naissance et certaines préférences) directement dans l’Appli ou sur le Site ; ou
      </p>

      <p>
      (2) en nous permettant d’accéder à vos Données personnelles à partir de votre profil sur le site de mise en réseau social Facebook et/ou de vos informations de connexion Google.
      </p>

      <p>
      Il est de votre responsabilité de fournir des informations à jour, complètes, véridiques et exactes, y compris pour les Données personnelles, et de maintenir ces informations à jour. Si vous ne fournissez pas ces informations, cela peut signifier que la Blanchisserie n’est pas en mesure de vous fournir ses Services. Alliance n’est pas responsable des problèmes ou de la responsabilité liés à des Données personnelles inexactes ou incomplètes, que ce soit en raison de votre incapacité à mettre à jour ces Données personnelles ou autrement.
      </p>

      <p>
      En plus des Données personnelles, nous pouvons être amenés à recueillir les informations suivantes vous concernant (définies collectivement avec vos Données personnelles comme les « Informations ») :
      </p>

      <p>
        <ul>
          <li>
          Certaines informations standard relatives à votre navigateur, telles que votre type de navigateur, votre adresse IP et les heures d’accès ;
          </li>

          <li>
          Informations en rapport avec les communications que vous nous envoyez (par exemple pour signaler un problème ou soumettre des requêtes) ; et
          </li>

          <li>
          Historique des transactions.
          </li>
        </ul>
      </p>

      <p>
      Nous ne conserverons pas vos Données personnelles, sauf si la loi l’exige ou conformément aux instructions de la Blanchisserie.
      </p>

      <h3>
      UTILISATION DE VOS DONNÉES.
      </h3>

      <p>
      La Blanchisserie peut utiliser vos Données personnelles à des fins légitimes, notamment pour que la Blanchisserie puisse vous fournir les Services. Plus précisément : nous fournissons vos Données à la Blanchisserie conformément à ses instructions, afin de lui permettre (i) d’afficher des publicités et du contenu adaptés à vos préférences et intérêts (ii) d’exploiter les Services et (iii) de répondre aux demandes d’assistance. Certaines caractéristiques de
      </p>

      <p>
      l’Appli permettent la mise en réseau. Vous devez vous assurer, lorsque vous utilisez ces fonctionnalités, que vous n’envoyez aucune Donnée personnelle que vous ne souhaitez pas voir envoyée, collectée ou utilisée par d’autres utilisateurs, telle que les données de votre profil ou votre adresse e-mail.
      </p>

      <p>
      La Blanchisserie peut également utiliser vos Données pour communiquer avec vous, en vous fournissant par exemple des avis obligatoires ou des avis discrétionnaires concernant les Services. De temps en temps, conformément à la législation en vigueur, la Blanchisserie peut également vous envoyer des sondages ou des courriers promotionnels pour vous informer d’autres produits ou services disponibles auprès de la Blanchisserie, sauf si vous nous signalez, à tout moment, que vous ne souhaitez pas être contacté à cet effet.
      </p>

      <h3>
      POUR LES UTILISATEURS RÉSIDANT HORS DES ÉTATS-UNIS D’AMÉRIQUE
      </h3>

      <p>
      Alliance a son siège social aux États-Unis d’Amérique et, sous réserve de la législation en vigueur, peut transférer vos Données aux États-Unis d’Amérique, afin de les transmettre à la Blanchisserie. Si Alliance transfère vos données en dehors de votre pays d’origine, elle veillera à ce que le transfert soit effectué conformément à la législation en vigueur.
      </p>

      <p>
      En cas de transfert des Données d’Utilisateurs résidant au Royaume-Uni ou dans l’EEE en dehors du Royaume-Uni ou de l’EEE, ou, dans le cas d’Utilisateurs résidant en Australie, en dehors de l’Australie, nous imposerons ou ils imposeront des obligations contractuelles aux destinataires de ces données, afin de protéger vos données (a) dans le cas d’Utilisateurs résidant au Royaume-Uni ou dans l’EEE, conformément à la norme requise au Royaume-Uni ou dans l’EEE, ou (b) dans le cas d’Utilisateurs résidant en Australie, en Australie.
      </p>

      <p>
      Conformément aux instructions de la Blanchisserie, nous pouvons également exiger des destinataires qu’ils souscrivent à des cadres internationaux destinés à permettre un partage de données sécurisé. Dans le cas de transferts de notre part, nous pouvons également transférer vos données si : a) dans le cas d’utilisateurs résidant au Royaume-Uni ou dans l’EEE : (i) le transfert est effectué vers un pays considéré comme offrant une protection adéquate de vos données par le gouvernement britannique ou la Commission européenne ; ou (ii) Alliance a mis en place l’une des « garanties appropriées » visées dans la législation en vigueur ; ou, (b) dans le cas des Utilisateurs résidant en Australie : (i) nous prenons des mesures raisonnables pour nous assurer que le destinataire de ces Données ne viole pas les principes de confidentialité australiens (« APP ») en vertu de la loi de 1988 sur la protection de la vie privée (Commonwealth Privacy Act) ; ou (ii) nous demeurons responsables des actes ou des pratiques du destinataire de ces données.
      </p>

      <h3>
      DIVULGATION DE VOS DONNÉES PERSONNELLES À DES TIERS.
      </h3>

      <p>
      Sauf indication contraire dans la présente Politique de confidentialité, vos Données personnelles ne seront partagées qu’avec la Blanchisserie, Alliance et ses filiales et sociétés affiliées, et elles ne seront divulguées à aucun autre tiers à votre insu.
      </p>

      <p>
      Si nous partageons vos données avec des tiers, une telle divulgation est à tout moment conforme à la législation en vigueur sur la protection des données et aux instructions émises par la Blanchisserie. En accédant à votre Compte, vous pouvez choisir de partager vos Données personnelles avec la Blanchisserie.
      </p>

      <p>
      La Blanchisserie peut demander à Alliance d’engager d’autres entreprises pour fournir des services administratifs et de communication limités à la Blanchisserie, tels que l’hébergement du Site, le traitement et la livraison des publipostages, la fourniture d’un support client ou la fourniture de services de traitement de cartes de crédit. Alliance a le droit de divulguer vos Données personnelles, conformément aux instructions de la Blanchisserie à ces sociétés tierces, dans le but d’administrer et de maintenir les fonctionnalités, fonctions et opérations du Site et de l’Appli, afin de permettre à la Blanchisserie de vous fournir les Services. Ces sociétés ne pourront obtenir que les Données personnelles qui leur sont nécessaires pour fournir les services en question, et la Blanchisserie n’autorise pas ces sociétés à utiliser vos Données personnelles, excepté dans le but de fournir les services demandés par Alliance pour le compte de la Blanchisserie.
      </p>

      <p>
      Les autres destinataires, ou catégories de destinataires, avec lesquels la Blanchisserie peut partager vos Données personnelles comprennent : (i) le cadre des obligations légales ou de la protection des droits légaux (comme la lutte contre la criminalité) ; (ii) les agences de prévention contre la fraude qui peuvent l’utiliser pour vérifier votre identité ; (iii) d’autres entités commerciales avec lesquelles la Blanchisserie envisage de fusionner ou par lesquelles la Blanchisserie envisage d’être acquise, ou si la Blanchisserie fait l’objet d’une réorganisation avec cette entité ; ou (iv) tout successeur intéressé, en cas de liquidation ou d’administration de la Blanchisserie.
      </p>

      <p>
      Nous ne sommes pas tenus de remettre en question ou de contester la validité de tout mandat de perquisition, d’assignation ou de toute autre demande gouvernementale similaire que nous recevons. Dans certains cas, une Blanchisserie peut choisir d’acheter ou de vendre des actifs. Dans ce type de transaction, les données des utilisateurs font généralement partie des actifs transférés. De plus, si une Blanchisserie ou la quasi-totalité de ses actifs était acquise, les données des utilisateurs feraient partie des actifs transférés ou acquis par un tiers.
      </p>

      <h3>
      BASE JURIDIQUE DU TRAITEMENT.
      </h3>

      <p>
      La présente Politique de confidentialité décrit les fins pour lesquelles nous recueillons vos Données pour le compte de la Blanchisserie et pour lesquelles la Blanchisserie peut utiliser vos Données personnelles. La Blanchisserie peut s’appuyer sur l’une des bases juridiques suivantes pour traiter vos Données ; (a) vous donnez/avez donné votre consentement éclairé pour qu’une Blanchisserie traite vos Données à des fins spécifiques ; (b) le traitement est nécessaire pour que la Blanchisserie remplisse ses obligations en vertu d’un accord que vous avez conclu avec elle ; (c) le traitement est nécessaire pour que la Blanchisserie se conforme à la loi (à l’exclusion des obligations contractuelles) ; (d) le traitement est nécessaire pour protéger la vie de quelqu’un ; (e) le traitement est nécessaire pour que la Blanchisserie exécute une tâche dans l’intérêt public ou pour les fonctions officielles de la Blanchisserie, et la tâche ou la fonction a une base juridique claire ; ou (f) le traitement est nécessaire pour les intérêts légitimes de la Blanchisserie ou les intérêts légitimes d’un tiers (sauf s’il existe une bonne raison de protéger vos Données qui l’emporte sur ces intérêts légitimes). Pour plus de détails sur la base juridique sur laquelle la Blanchisserie se fonde pour traiter vos données, veuillez consulter la Politique de confidentialité de la Blanchisserie.
      </p>

      <h3>
      SÉCURITÉ DE VOS DONNÉES PERSONNELLES.
      </h3>

      <p>
      Alliance utilise des pratiques standard, ayant généralement cours dans le secteur, telles que les pare-feu, le cryptage et, dans certaines zones, le protocole sécurisé SSL, afin de protéger vos Données conformément aux instructions de la Blanchisserie. Cependant, aussi efficace que soit la technologie de cryptage, aucun système de sécurité n’est impénétrable. Bien que nous maintenions des mesures de protection physiques, électroniques et administratives pour protéger vos données personnelles contre tout accès non autorisé ou inapproprié, Alliance ne peut garantir la sécurité de notre base de données, et nous ne pouvons pas non plus garantir que les informations que vous fournissez ne seront pas interceptées lors de leur transmission à Alliance sur Internet. Alliance vous recommande de ne divulguer votre mot de passe à personne. Si nous recueillons des Données personnelles vous concernant pour le compte de la Blanchisserie, par le biais de l’utilisation de notre Appli et/ou des Services de la Blanchisserie, la Blanchisserie ne conservera ces données que le temps raisonnablement nécessaire pour accomplir l’activité demandée, ou comme la loi l’exige.
      </p>

      <h3>
      DONNÉES COLLECTÉES AUTOMATIQUEMENT.
      </h3>

      <p>
      Nous pouvons recevoir et stocker (pour le compte de la Blanchisserie) certains types de données chaque fois que vous interagissez avec l’Appli et/ou le Service. La Blanchisserie peut nous demander de recevoir et d’enregistrer automatiquement des données sur nos journaux de serveur à partir de votre navigateur, y compris votre adresse IP, les informations sur les cookies et la page que vous avez demandée.
      </p>

      <p>
      En outre, la Blanchisserie peut utiliser des fournisseurs et des produits d’analyse tiers pour obtenir, compiler et analyser des informations sur la façon dont les Utilisateurs utilisent les Services et interagissent avec eux. Sur instruction d’une Blanchisserie, nous pouvons fournir des Données à ces fournisseurs d’analyse dans le but d’obtenir des statistiques et d’autres informations sur la façon dont les utilisateurs utilisent la Blanchisserie et interagissent avec elle. Ces fournisseurs d’analyses de données peuvent utiliser différents outils ou technologies de suivi reconnus ou nouveaux (y compris, sans s’y limiter, les cookies, les balises web, le cache HTTP, les objets locaux partagés et les identificateurs permanents) pour reconnaître votre ordinateur ou appareil et/ou collecter ou compiler ces données. Alliance n’a aucun contrôle sur les technologies, les outils ou les pratiques des tiers qui fournissent des produits et services d’analyse de données à la Blanchisserie. Pour plus d’informations sur les données collectées automatiquement par la Blanchisserie, veuillez consulter la politique de confidentialité de la Blanchisserie.
      </p>

      <h3>
      COLLECTE ET UTILISATION DES DONNÉES PERSONNELLES DES ENFANTS.
      </h3>

      <p>
      L’Appli et les Services sont destinés à un public général, et Alliance ne recueille délibérément aucune Donnée personnelle sur les enfants. Lors du processus de création et d’enregistrement d’un compte, Alliance recueille des informations sur l’âge et, si les utilisateurs s’identifient comme étant âgés de moins de 13 ans, Alliance empêche ces utilisateurs de fournir des données personnelles.
      </p>

      <h3>
      VOS DROITS RELATIFS À VOS DONNÉES PERSONNELLES.
      </h3>

      <p>
      En fonction de votre juridiction, vous pouvez avoir certains droits concernant vos données personnelles, y compris le droit de : (i) être informé du Traitement de vos données personnelles (c’est-à-dire les données fournies dans la présente Politique de confidentialité et dans la politique de confidentialité de la Blanchisserie) ; (ii) demander l’accès à ou une copie de toutes les données personnelles que la Blanchisserie détient à votre sujet ; (iii) demander la rectification de vos données personnelles si vous estimez qu’elles sont inexactes ; (iv) demander la suppression de vos données personnelles ; (v) vous opposer au traitement de vos données personnelles dans un but particulier ou demander à la Blanchisserie de cesser d’utiliser vos données ; (vi) demander à ne pas faire l’objet d’une décision basée sur un traitement automatisé et la mise en place de garanties si vous êtes identifié sur la base de vos données personnelles ; (vii) nous demander de vous transférer une copie de vos données personnelles ou de transférer une copie de vos données personnelles à un autre fournisseur de services ou à un tiers lorsque cela est techniquement possible ; et (viii) demander à la Blanchisserie d’arrêter ou de commencer à vous envoyer des messages marketing à tout moment en envoyant un e-mail à la Blanchisserie. Selon votre juridiction, certains droits ou tous ces droits peuvent être limités d’une manière ou d’une autre. Notez, en outre, que chaque courrier électronique que la Blanchisserie vous enverra contiendra des informations sur la façon dont vous pouvez vous désinscrire. Veuillez remarquer que le traitement de votre demande peut prendre jusqu’à 28 jours.
      </p>

      <p>
      Si vous souhaitez exercer l’un de vos droits relatifs à vos données personnelles, vous devez contacter la Blanchisserie. Si vous préférez, vous pouvez nous contacter à l’adresse softwaresupport@alliancels.com et nous informerons la Blanchisserie. L’objet de ces e-mails doit mentionner « Droits des personnes concernées ». La Blanchisserie traitera ces demandes conformément à la législation sur la protection des données.
      </p>

      <h3>
      QUESTIONS OU COMMENTAIRES CONCERNANT LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ.
      </h3>

      <p>
      Si vous avez des questions ou des commentaires sur l’utilisation de vos Données personnelles ou la présente Politique de confidentialité, vous devez contacter la Blanchisserie. Vous pouvez également envoyer un e-mail à l’adresse softwaresupport@alliancels.com et nous transmettrons votre demande au service concerné de la Blanchisserie.
      </p>

      <p>
      Si vous résidez dans l’UE et que vous avez des inquiétudes concernant l’utilisation de vos données par la Blanchisserie, vous avez également le droit de déposer une réclamation auprès du régulateur de la protection des données de votre pays. Pour les coordonnées, consultez la page suivante : https://edpb.europa.eu/about-edpb/board/members_en.
      </p>

      <p>
      Si vous résidez au Royaume-Uni et que vous avez des inquiétudes concernant l’utilisation de vos données, vous avez le droit de déposer une réclamation auprès du bureau du Commissaire à l’information. Pour les coordonnées, consultez la page suivante : https://ico.org.uk/global/contact-us/.
      </p>

      <p>
      Si vous résidez en Australie, vous reconnaissez que la Blanchisserie et nous sommes liés par les APP. Si vous avez des questions, des préoccupations ou des réclamations concernant la présente Politique de confidentialité ou la façon dont nous traitons vos données personnelles, veuillez contacter l’adresse indiquée ci-dessus ; nous examinerons votre réclamation et nous vous répondrons dans un délai raisonnable. Si vous n’êtes pas satisfait du traitement de votre réclamation ou de votre question, vous pouvez contacter le bureau du Commissaire australien à l’information :
      </p>

      <p>
      Bureau du Commissaire australien à l’information
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      Téléphone: 1300 363 992
      <br />
      Adresse email: enquiries@oaic.gov.au
      <br />
      If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
      </p>

    </div>
  )
}
