export const translations = {
    "reset_password": "암호를 재설정",
    "new_password": "새 비밀번호",
    "confirm_password": "비밀번호 확인",
    "submit": "제출하다",
    "error_password_min_length": "비밀번호는 8자 이상이어야 합니다.",
    "error_password_max_length": "비밀번호는 24자 이하여야 합니다.",
    "error_passwords_do_not_match": "비밀번호가 일치하지 않습니다",
    "password_success": "비밀번호가 성공적으로 변경되었습니다.",
    "success_new_password_mobile": "이제 모바일 앱에서 새 비밀번호로 로그인할 수 있습니다.",
    "no_token_error": "오류. 비밀번호 재설정 이메일의 링크를 따르십시오.",
    "reset_password_save_error": "오류. 다시 시도해 주세요. 비밀번호를 요청한 후 1시간 이내에 재설정할 수 있습니다.",
    "password_requirements": "비밀번호는 8~24자 사이여야 하며 숫자를 포함하고 대문자와 소문자를 모두 포함해야 합니다. 공백이 허용되지 않습니다.",
    "updating": "업데이트 중"
};