import React from 'react'
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className="navigation">
      <ul>
        <li>
          <Link to="/terms">Terms</Link>
        </li>

        <li>
          <Link to="/privacy">Privacy</Link>
        </li>
      </ul>
    </div>
  )
}
