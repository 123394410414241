import React from 'react'
import { buildTermsUrl, buildPrivacyUrl, buildCppaUrl } from '../../../utils/brands'

export default function PrivacyEnUs() {
  const termsUrl = buildTermsUrl('en-us');
  const privacyUrl = buildPrivacyUrl('en-us');
  const cppaUrl = buildCppaUrl()

  return (
    <div>
      <h2>
      PRIVACY POLICY
      </h2>

      <p>
      Alliance Laundry Systems LLC ("Alliance", "we", "us", "our"), provides a mobile application and a related website (the “App” or the “Site”) to you (the terms “you” “your” and “yours” shall refer to any users of the App or Site (“Users”) in order for you to access the laundry services of our third party laundry providers (the “Laundry Facility”) (those third party services being referred to as the “Service” or "Services"). The relevant Laundry Facility is the controller of your Personal Information (as defined herein) and Alliance processes( as defined herein) your Personal Information on behalf of the Laundry Facility and as a processor of the Laundry Facility.
      </p>

      <p>
      The terms of this privacy policy ("Privacy Policy") explains the way your data is used. Where you provide information through the App or Site, downloading, registering for an App account (an "Account"), using any part of the App, submitting information or using Services provided by Laundry Facility via the App, the practices and policies outlined in this Privacy Policy shall apply. This Privacy Policy is intended to supplement the privacy policy of the Laundry Facility, please refer to the Laundry Facility's policy for further information on how they use your Personal Information collected through the App or Site.
      </p>

      <p>
      Terms not defined herein shall have the meaning as provided in Alliance's Terms of Service, found at <a href={termsUrl} target="_blank" rel="noreferrer">{termsUrl}</a> and incorporated herein.
      </p>

      <blockquote>
      SUBJECT TO APPLICABLE LAW WE MAY MODIFY THIS PRIVACY POLICY AT ANY TIME AND SUCH MODIFICATION SHALL BE EFFECTIVE IMMEDIATELY UPON EITHER POSTING OF THE MODIFIED PRIVACY POLICY ON THE SITE, ON THE APP, OR NOTIFYING YOU. PLEASE REVIEW THIS PRIVACY POLICY PERIODICALLY TO ENSURE THAT YOU ARE AWARE OF ANY MODIFICATIONS. IF YOU DO NOT ACCEPT THE CHANGES MADE TO THE MODIFIED AGREEMENT YOU SHOULD IMMEDIATELY CEASE ALL USE OF THE APP. YOUR CONTINUED ACCESS OR USE OF THE APP SHALL BE DEEMED YOUR CONCLUSIVE ACCEPTANCE OF THE MODIFIED PRIVACY POLICY.
      </blockquote>

      <p>
      This revised Privacy Policy is effective as of April 1, 2021. For information in relation to how we handle personal data for our own purposes, please see our other privacy policies (governing our practices in relation to our wider business) can be found here: <a href={privacyUrl} target="_blank" rel="noreferrer">{privacyUrl}</a>
      </p>

      <h3>
      INFORMATION COVERED IN THIS PRIVACY POLICY
      </h3>

      <p>
      This Privacy Policy covers the Laundry Facility's use of your Personal Information that Alliance gathers on behalf of and under the instruction of the Laundry Facility (“Processes.”)
      </p>

      <h3>
      COLLECTION OF YOUR INFORMATION
      </h3>

      <p>
      In order to access the App, Site and the Laundry Facility’s Services, you will be required to register for an Account. You may register for an Account by providing certain personal data, which means information that can be used to identify you ("Personal Information"), either by:
      </p>

      <p>
      (1) entering such Personal Information (such as, and as applicable, your title, your name, e-mail address, addresses, telephone number, credit card and other financial information, your age, your date of birth and certain preferences) directly into the App or Site; or
      </p>

      <p>
      (2) by allowing us to access your Personal Information from your profile on the Facebook social networking site and/or your Google log-in information.
      </p>

      <p>
      It is your responsibility to give current, complete, truthful and accurate information, including Personal Information, and to keep such information up to date. If you do not provide the information, this may mean the Laundry Facility is unable to provide you with the Services. Alliance will not be responsible for any problems or liability related to inaccurate or incomplete Personal Information, whether due to your failure to update such Personal Information or otherwise.
      </p>

      <p>
      In addition to Personal Information, we may collect the following information you provide (collectively with your Personal Information the "Information"):
      </p>

      <p>
        <ul>
          <li>
          Certain standard browser information, such as your browser type, IP address, and access times;
          </li>

          <li>
          Information in connection with communications you send us (for example to report a problem or submit queries); and
          </li>

          <li>
          Transaction history.
          </li>
        </ul>
      </p>

      <p>
      We will not retain your Personal Information unless as required by any applicable law or as instructed by Laundry Facility.
      </p>

      <h3>
      USE OF YOUR INFORMATION.
      </h3>

      <p>
      The Laundry Facility may use your Personal Information for a number of legitimate purposes, including so the Laundry Facility can provide the Services to you. More specifically: we provide your Information to the Laundry Facility at their direction to enable them to (i) display advertising and content that are tailored to your preferences and interests (ii) operate the Services and (iii) respond to support requests. Certain features of the
      </p>

      <p>
      The App allows for social networking. You should ensure when using these features that you do not submit any Personal Information that you do not want to be sent, collected or used by other users, such as profile details or your e-mail address.
      </p>

      <p>
      The Laundry Facility may also use your Information to communicate with you, such as providing you with mandatory notices or discretionary notices about the Services. From time to time, in accordance with applicable legislation, the Laundry Facility may also send you surveys or promotional mailings to inform you of other products or services available from the Laundry Facility, unless you let us know at any time that you do not wish to be contacted for this purpose.
      </p>

      <h3>
      FOR USERS OUTSIDE THE UNITED STATES OF AMERICA
      </h3>

      <p>
      Alliance is headquartered in the United States of America, and, subject to applicable law, may transfer your Information to the United States of America in order to provide your Information to the Laundry Facility. In the event, Alliance does transfer your data outside of your home country, Alliance will ensure that the transfer is made in compliance with applicable law.
      </p>

      <p>
      When we transfer Information of Users in the UK or EEA outside the UK or EEA, or, in the case of Users in Australia, outside Australia, we or they will impose contractual obligations on the recipients of that data to protect your information to (a) in the case of Users in the UK or EEA, the standard required in the UK or EEA, or, (b) in the case of Users in Australia, Australia.
      </p>

      <p>
      We may also require, in accordance with any instructions from the Laundry Facility, any recipient to subscribe to international frameworks intended to enable secure data sharing. In the case of transfers by us, we may also transfer your information if: (a) in the case of users in the UK or the EEA: (i) the transfer is to a country deemed to provide adequate protection of your information by the UK government or European Commission; or (ii) Alliance put in place one of the ‘appropriate safeguards’ referred to in applicable legislation; or, (b) in the case of Users in Australia: (i) we take reasonable steps to ensure the recipient of that Information does not breach the Australian Privacy Principles ("APP") under the Privacy Act 1988 (Cth); or (ii) we remain accountable for the acts or practices of the recipient of that information.
      </p>

      <h3>
      DISCLOSING OF YOUR PERSONAL INFORMATION TO THIRD PARTIES.
      </h3>

      <p>
      Except as described in this Privacy Policy, your Personal Information will be shared only with the Laundry Facility, Alliance and its controlled subsidiaries and affiliates, and your Personal Information will not be disclosed to any other third party without your knowledge.
      </p>

      <p>
      If we share your information with third parties, any such disclosure is at all times in compliance with applicable data protection law and any instructions issued by the Laundry Facility. By accessing your Account, you may choose to share your Personal Information with the Laundry Facility.
      </p>

      <p>
      The Laundry Facility may request Alliance to hire other companies to provide limited administrative and communication services to the Laundry Facility, such as hosting the Site, the processing and delivery of mailings, providing customer support, or providing credit card processing services. Alliance has the right to disclose your Personal Information in accordance with the instructions of the Laundry Facility to these third party companies for the purpose of administering and maintaining the Site and App's features, functions and operations so that the Services can be provided by the Laundry Facility to you. Those companies will be permitted to obtain only such Personal Information as necessary for them to deliver the respective services, and the Laundry Facility does not authorize these companies to use your Personal Information except for the sole purpose of providing those services requested by Alliance on behalf of the Laundry Facility.
      </p>

      <p>
      Other recipients, or categories of recipients, that the Laundry Facility may share your Personal Information with include: (i) in connection with legal obligations or protection of legal rights (such as crime prevention); (ii) fraud prevention agencies who may use it to verify your identity; (iii) other business entities the Laundry Facility plans to merge with or be acquired by, or if the Laundry Facility undergoes a re-organisation with that entity; or (iv) any successor in interest, in the event of a liquidation or administration of the Laundry Facility.
      </p>

      <p>
      We are not required to question or contest the validity of any search warrant, subpoena or other similar governmental request that we receive. In some cases, a Laundry Facility may choose to buy or sell assets. In these types of transactions, user information is typically one of the business assets that is transferred. Moreover, if a Laundry Facility, or substantially all of their assets, were acquired, user information would be one of the assets that is transferred or acquired by a third party.
      </p>

      <h3>
      LEGAL BASIS FOR PROCESSING.
      </h3>

      <p>
      This Privacy Policy has described the purposes for which we collect your Information on behalf of the Laundry Facility and for which the Laundry Facility may use your Personal Information. The Laundry Facility may rely on one of the following legal bases to process your Information; (a) you provide have provided clear consent for a Laundry Facility to process your Information for a specific purpose; (b) the processing is necessary for the Laundry Facility to fulfil its obligations under an agreement you have with the Laundry Facility,(c) the processing is necessary for the Laundry Facility to comply with the law (not including contractual obligations); (d): the processing is necessary to protect someone’s life; (e) the processing is necessary for the Laundry Facility to perform a task in the public interest or for the Laundry Facility official functions, and the task or function has a clear basis in law; or (f) the processing is necessary for the Laundry Facility legitimate interests or the legitimate interests of a third party, (unless there is a good reason to protect the your Information which overrides those legitimate interests). For more detail on what legal basis the Laundry Facility is relying on to process your data, please refer to the Laundry Facility privacy policy.
      </p>

      <h3>
      SECURITY OF YOUR PERSONAL INFORMATION.
      </h3>

      <p>
      Alliance uses standard, industry-wide practices such as firewalls, encryption, and (in certain areas) secure socket layers for protecting your Information in accordance with the Laundry Facility instructions. However, as effective as encryption technology is, no security system is impenetrable. Although we maintain physical, electronic, and administrative safeguards to protect your personal information from unauthorized or inappropriate access, Alliance cannot guarantee the security of our database, nor can we guarantee that information you supply won't be intercepted while being transmitted to Alliance over the Internet. Alliance recommends that you not disclose your password to anyone. If we collect Personal Information about you on behalf of the Laundry Facility through the use of our App and/or the Laundry Facility Services, the Laundry Facility will only retain that information for as long as is reasonably necessary to fulfil the activity requested, or as may be required by law.
      </p>

      <h3>
      INFORMATION COLLECTED AUTOMATICALLY.
      </h3>

      <p>
      We may receive and store (on behalf of the Laundry Facility) certain types of information whenever you interact with the App and/or Service. Laundry Facility may instruct us to automatically receive and record information on our server logs from your browser including your IP address, cookie information, and the page you requested.
      </p>

      <p>
      In addition, Laundry Facility may use third party analytics providers and products to obtain, compile and analyze Information about how Users are using and interacting with the Services. If instructed by a Laundry Facility, we may provide Information to these analytics providers for the purpose of obtaining statistics and other information about how users are using and interacting with Laundry Facility. These analytics providers may use a variety of established or new tracking technologies or tools (including, without limitation, cookies, web beacons, HTTP cache, local shared objects and persistent identifiers) to recognize your computer or device and/or to collect or compile this information. Alliance has no control over the technologies, tools or practices of the third parties that providing analytics products and services to the Laundry Facility. For more information on which information Laundry Facility collect automatically, please refer to the Laundry Facility’s privacy policy.
      </p>

      <h3>
      COLLECTION AND USE OF CHILDREN'S PERSONAL INFORMATION.
      </h3>

      <p>
      The App and the Services are intended for general audiences, and Alliance does not knowingly collect any Personal Information from children. During the registration process for an Account, Alliance does collect age information, and if users identify themselves as under 13 years of age, Alliance will block such users from providing personal information.
      </p>

      <h3>
      YOUR RIGHTS IN RELATION TO YOUR PERSONAL INFORMATION.
      </h3>

      <p>
      Depending on your jurisdiction, you may have certain rights with respect to your personal data, including the right to: (i) be informed about the Processing of your personal data (i.e. the information provided in this Privacy Policy and the privacy policy of the Laundry Facility); (ii) request access to or a copy of any personal data the Laundry Facility hold about you; (iii) request the rectification of your personal data, if you consider that it is inaccurate; (iv) request the erasure of your personal data; (v) object to your personal data being processed for a particular purpose or to request that the Laundry Facility stop using your information; (vi) request not to be subject to a decision based on automated processing and to have safeguards put in place if you are being profiled based on your personal data; (vii) ask us to transfer a copy of your personal data to you or to another service provider or third party where technically feasible; and (viii) ask the Laundry Facility to stop or start sending you marketing messages at any time by sending an email to the Laundry Facility. Depending on your jurisdiction, some or all of these rights may be limited in some way. Note that, in addition, each electronic mailing the Laundry Facility sends you will contain details of how you can unsubscribe. Please note that it may take up to 28 days to action your request.
      </p>

      <p>
      If you wish to exercise any of your rights in relation to your Personal Information you should contact the Laundry Facility. If you prefer, you may contact us at softwaresupport@alliancels.com and we shall notify the Laundry Facility. These emails should be marked with the subject "Data Subjects Rights". The Laundry Facility will Process such a request in accordance with Data Protection Legislation.
      </p>

      <h3>
      QUESTIONS OR COMMENTS REGARDING THIS PRIVACY POLICY.
      </h3>

      <p>
      If you have any questions or comments about the use of your Personal Information or this Privacy Policy, you should contact the Laundry Facility. Alternatively, please contact softwaresupport@alliancels.com and we will pass your query on to the relevant Laundry Facility .
      </p>

      <p>
      If you are located in the EU, if you have any concerns about the Laundry Facility's use of your information, you also have the right to make a complaint to the data protection regulator in your country. For contact details, see: https://edpb.europa.eu/about-edpb/board/members_en.
      </p>

      <p>
      If you are located in the UK, if you have any concerns about the use of your information, you have the right to make a complaint to the Information Commissioner’s Office. For contact details, see: https://ico.org.uk/global/contact-us/.
      </p>

      <p>
      If you are located in Australia, you acknowledge that we and the Laundry Facility are bound by the APPs. If you have any questions, concerns or complaints about this Privacy Policy, or how we handle your personal information, please contact the address set out above, and we will review your complaint and respond within a reasonable period. If you are dissatisfied with the handling of your complaint or concern, you may contact the Office of the Australian Information Commissioner:
      </p>

      <p>
      Office of the Australian Information Commissioner
      <br />
      GPO Box 5218
      <br />
      Sydney NSW 2001
      <br />
      Telephone: 1300 363 992
      <br />
      Email: enquiries@oaic.gov.au
      <br />
      If you are located in California, USA, please also refer to our CCPA Privacy Policy Addendum located <a className="external-link" href={cppaUrl} target="_blank" rel="noreferrer">here</a>
      </p>

    </div>
  )
}
